import React from 'react';
import commonAction from '@/actions/common'
import { connect } from 'react-redux'
import './Log.scss'
import Step from '@/components/Step'
import { getOrderLogInfo } from '@/api/TryOutPage/Log'

class Log extends React.Component {
  constructor(props) {
    super(props)
    this.orderId = null
    this.state = {
      stepList: []
    }
  }
  componentDidMount() {
    const pathname = this.props.path;
    this.props.dispatch({ type: commonAction.setPathname, pathname })
    this.props.onRef(this);
    this.orderId = this.props.match.params.id
    this.getOrderLogInfoFun()
  }

  getOrderLogInfoFun = async() => {
    const res = await getOrderLogInfo({orderId: this.orderId})
    if (res.code === 200) {
      this.setState({
        stepList: [...res.data.reverse()]
      })
    }
  }

  submit = () => {
    console.log("子通过父触发兄弟的方法 mydetail");
  }

  render() {
    return (
      <div className="logBox commonBodyBox">
        <Step stepList={this.state.stepList}/>
      </div>
    )
  }
}

export default connect()(Log);