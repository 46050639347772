import React from 'react'
import './Step.scss'



class Step extends React.Component {
  render() {
    const stepList = this.props.stepList
    if (!stepList || !stepList.length) {
      return <div></div>
    }
    const ele = stepList.map(item=> {
      return (
        <li key={item.id}>
        <span>{item.statusName}</span>
        <i></i>
        <p>
        <span>时间：{item.updateTime}</span>
          <span>地址：{item.address}</span>
        </p>
      </li>
      )
    })
    return (
      <div className="stepBox">
        <ul>
          {[...ele]}
        </ul>
      </div>
    )
  }
}


export default Step