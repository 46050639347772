import React, { useEffect, useRef } from "react";
import { Button, Toast } from "antd-mobile";
import { useLocation } from "react-router-dom";
import wx from "weixin-js-sdk";

import "./index.scss";
import { bindWx } from "../../api/Sys";


function getRequest(location) {
  const url = location.search; //获取url中"?"符后的字串
  let  theRequest = new Object();
  if (url.indexOf("?") != -1) {
    const str = url.substr(1);
      const strs = str.split("&");
      for(var i = 0; i < strs.length; i ++) {
          theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
      }
  }
  return theRequest;
}

const ShouQuan = () => {
  const location = useLocation();
  const paramsRef = useRef(null)
  const handleClick = async () => {
    const res = await bindWx(paramsRef.current);
    if (res.code === 200) {
      Toast.success("绑定成功");
      setTimeout(() => {
        wx.closeWindow();
      }, 1000);
    } else {
      Toast.fail(res.message);
    }
  };
  const handleCancel = () => {
    wx.closeWindow();
  };
  useEffect(() => {
    const params = getRequest(location)
    paramsRef.current = params
  }, []);
  return (
    <div className="shouquan">
      <div>
        <h3>是否授权</h3>
        <div className="buttonBox">
          <div>
            <Button onClick={handleCancel} size="large">
              取消
            </Button>
          </div>
          <div>
            <Button onClick={handleClick} type="primary" size="large">
              确定
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShouQuan;
