import React from 'react';
import './WorkOrderList.scss';
import { connect } from 'react-redux';
import commonAction from '@/actions/common'
import ScrollBox from '@/components/ScrollBox'
import ItemBox from '@/components/ItemBox'
import { getOrderPageList } from '@/api/TryOutPage/WorkOrderList'
import Lodding from '@/components/Lodding'
import NoData from '@/components/NoData'
class WorkOrderList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      isCanLoading: true,
      pageType: 1,
      scrollHight: 0,
      animating: false
    }
    this.pageIndex = 1
    this.pageSize = 20
    this.totalPage = 0
  }

  handleClickItem = (val) => {
    this.props.history.push({ pathname: `/touristslayout/workOrderDetail/${val.orderNo}` });
  }



  // 滚动加载更多
  handleScroll = () => {
    this.setState({
      isCanLoading: false
    })
    if (this.pageIndex < this.totalPage) {
      this.pageIndex += 1
      this.getOrderPageListFun()
    }
  }
  // 记录滚动的位置
  setScrollHight = (val) => {
    this.props.dispatch({ type: "setScrollHight", scrollHight: val })
  }

  // 刷新
  handleRefresh = () => {
    this.pageIndex = 1
    this.totalPage = 0
    this.keyword = ''
    this.setState({
      list: [],
      scrollHight: 0
    })
    this.setScrollHight(0)
    this.getOrderPageListFun()

  }

  componentDidMount() {
    const pathname = this.props.path;
    this.props.dispatch({ type: commonAction.setPathname, pathname })
    this.props.onRef(this);
    if (this.props.history.action === "POP") {
      this.pageIndex = this.props.workOrderListState.params.pageIndex
      this.keyword = this.props.workOrderListState.params.keyword
      this.totalPage = this.props.workOrderListState.totalPage
      this.setState({
        list: this.props.workOrderListState.list,
        scrollHight: this.props.workOrderListState.scrollHight
      })
    } else {
      this.getOrderPageListFun();
    }
  }
  componentWillUnmount() {
    if (this.timmer) {
      clearTimeout(this.props.timmer)
    }
  }

  changeTab = (index) => {
    if (index === this.state.pageType) {
      return
    }
    this.pageIndex = 1
    this.totalPage = 0
    this.setState({
      pageType: index,
      list: []
    }, () => {
      this.getOrderPageListFun()
    })
  }

  // 今日维修 今日保养数据 已经完成
  getOrderPageListFun = async () => {
    this.setState({
      animating: true
    })
    const params = {
      keyword: '',
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      pageType: this.state.pageType,
      liftId: this.props.match.params.id * 1
    }
    const res = await getOrderPageList(params)
    if (res.code === 200) {
      this.totalPage = res.data.totalPage;
      this.setState((state) => ({
        list: state.list.concat(res.data.list),
        isCanLoading: true,
      }))
      // 缓存搜索数据 今日维修 今日维保
      this.props.dispatch({ type: "setMaintenanceListState", payload: { list: this.state.list, totalPage: res.data.totalPage, params } })
    }
    this.setState({
      animating: false
    })
  }



  render() {
    return (
      <div className="workOrderListBox commonBodyBox">
        <div className="workOrderList">
          {
             this.state.list && this.state.list.length ? <ScrollBox scrollHight={this.state.scrollHight} setScrollHight={this.setScrollHight} handleRefresh={this.handleRefresh}  handleScroll={this.handleScroll} isCanLoading={this.state.isCanLoading}>
             <ItemBox handleClick={this.handleClickItem} list={this.state.list} type="TouristsWorkOrderList"/>
           </ScrollBox> : <NoData />
          }
          
        </div>
        <Lodding animating={this.state.animating}/>
      </div>
    )
  }
}



function mapStateToProps(state) {
  return {
    workOrderListState: state.touristsWorkOrderListR
  }

}

export default connect(mapStateToProps)(WorkOrderList);