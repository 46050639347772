import React from 'react'
import './NoData.scss'
class NoData extends React.Component {
  render() {
    return (
      <div className="noDataBox">
        暂无数据
      </div>
    )
  }
}

export default NoData;