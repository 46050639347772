import { fetch } from '../../plugin/axios'
const serverName = '/maintain'

/**
 * 获取维保日历
 * @param {} data 
 */
export function getCalendar(data) {
  return fetch({
    url: serverName + '/app/maintain/order/calendar',
		method: 'POST',
		data
  })
}

/**
 * 点击三个选项获取的弹窗
 * @param {} data 
 */
export function getCalendarDetailList(data) {
  return fetch({
    url: serverName + '/app/maintain/order/calendarDetailList',
		method: 'POST',
		data
  })
}



/**
 * 今日任务 今日超期 已完成
 * @param {} data 
 */
export function getOrderPageList(data) {
  return fetch({
    url: serverName + '/app/maintain/order/pageList',
		method: 'POST',
		data
  })
}