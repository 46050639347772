import { fetch } from '../../plugin/axios'
const serverName = '/maintain'

/**
 * 获取电梯详情
 * @param {} params 
 */
export function getLiftDetail(params) {
  return fetch({
    url: serverName + '/app/lift/liftDetail',
		method: 'GET',
		params
  })
}


/**
 * 获取项目下拉框列表
 * @param {} params 
 */
export function getProjectSimpleList(data) {
  return fetch({
    url: serverName + '/project/simpleList',
		method: 'POST',
		data
  })
}


/**
 * 修改电梯信息
 * @param {} params 
 */
export function errorCorrection(data) {
  return fetch({
    url: serverName + '/app/lift/errorCorrection',
		method: 'POST',
		data
  })
}


/**
 * 修改保养单
 * @param {} params 
 */
export function getTemplateSimpleList(params) {
  return fetch({
    url: serverName + '/maintain/template/simpleList',
		method: 'GET',
		params
  })
}


/**
 * 使用单位
 * @param {} params 
 */
export function getPropertyUnitSimpleList(data) {
  return fetch({
    url: '/auth/subPlatform/propertyUnit/simpleList',
		method: 'POST',
		data
  })
}