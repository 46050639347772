import { fetch } from '../../plugin/axios'
const serverName = '/maintain'

// 电梯列表
export function getMyLiftList(data) {
  return fetch({
    url: serverName + '/app/lift/myLiftList',
		method: 'POST',
		data
  })
}

// 任务指派电梯列表
export function getAssignLiftList(data) {
  return fetch({
    url: serverName + '/app/lift/assignLiftList',
		method: 'POST',
		data
  })
}

