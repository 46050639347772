import React from 'react'
import commonAction from '@/actions/common'
import { getUseUnit } from '@/api/TryOutPage/UseUnit'
import { connect } from 'react-redux';
import './UseUnit.scss'
class UseUnit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      detail: {
        unitName: '',
        cityName: '',
        districtName: '',
        provinceName: '',
        principal: '',
        principalPhone: '',
        consumerHotLine	: '',
        address: ''
      }
    }
  }
  componentWillMount() {
    this.props.dispatch({ type: commonAction.setPathname, pathname: this.props.path })
    this.props.onRef(this);
    this.getUseUnitFun(this.props.match.params.id * 1)
  }

  getUseUnitFun = async (liftId) => {
    const res = await getUseUnit({ liftId })
    if (res.code === 200 && res.data) {
      this.setState({
        detail: res.data
      })
    }
  }

  submit = () => {

  }
  render() {
    const { detail } = this.state
    return (
      <div className="useUnit">
        <div className="useUnitItem">
          <div>
            <p><span>使用单位：</span><span>{detail.unitName}</span></p>
            <p><span>当前电梯：</span><span>{detail.provinceName+detail.cityName+detail.districtName}</span></p>
            <p><span>负责人：</span><span>{detail.principal}</span></p>
            <p className="useUnitPhone"><span>联系电话：</span><a href={`tel:${detail.principalPhone}`}>{detail.principalPhone}</a></p>
            <p className="useUnitPhone"><span>客服电话：</span><a href={`tel:${detail.consumerHotLine}`}>{detail.consumerHotLine}</a></p>
            <p><span>通讯地址：</span><span>{detail.address}</span></p>
          </div>
        </div>
      </div>
    )
  }
}


export default connect()(UseUnit);