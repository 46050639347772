
import commonAction from '../actions/common'
const commonState = {
  headerConfig: {
    title: '',
    rightText: '',
    rightType: '',
    actionType: '',
  },
  pathname: '',
  '/layout/mylift': {
    title: '我的电梯',
    rightText: '搜索',
    rightType: 'text',
    actionType: 'search'
  },
  '/layout/toBeSigned': {
    title: '待签字',
    rightText: '搜索',
    rightType: 'text',
    actionType: 'search'
  },
  '/layout/mylift2': {
    title: '任务指派',
    rightText: '搜索',
    rightType: 'text',
    actionType: 'search'
  },
  '/layout/myliftdetail/:id': {
    title: '电梯详情',
    rightText: '纠错',
    rightType: 'text',
    actionType: 'search'
  },
  '/layout/myliftedit/:id': {
    title: '电梯编辑',
    rightText: '提交',
    rightType: 'text',
    actionType: 'search'
  },
  '/layout/calendar': {
    title: '维保日历',
    rightText: '',
    rightType: '',
    actionType: ''
  },
  '/layout/maintenanceList': {
    title: '维保单列表',
    rightText: '',
    rightType: '',
    actionType: ''
  },
  '/layout/workOrderDetails/:id': {
    title: '工单详情',
    rightText: '日志',
    rightType: 'text',
    actionType: 'search'
  },
  '/layout/log/:id': {
    title: '保养日志',
    rightText: '',
    rightType: '',
    actionType: ''
  },
  '/layout/elevatorMaintenance': {
    title: '电梯维修',
    rightText: '提交',
    rightType: 'text',
    actionType: 'search'
  },


  //游客试用header配置
  '/touristslayout/myLiftDetail/:id': {
    title: '电梯详情',
    rightText: '',
    rightType: 'text',
    actionType: 'search'
  },
  '/touristslayout/useUnit/:id': {
    title: '使用单位',
    rightText: '',
    rightType: 'text',
    actionType: 'search'
  },
  '/touristslayout/emergencyRescue/:id': {
    title: '紧急救援',
    rightText: '',
    rightType: 'text',
    actionType: 'search'
  },
  '/touristslayout/workOrderList/:id': {
    title: '工单列表',
    rightText: '',
    rightType: 'text',
    actionType: 'search'
  },
  '/touristslayout/workOrderDetail/:id': {
    title: '工单详情',
    rightText: '',
    rightType: 'text',
    actionType: 'search'
  },
  '/touristslayout/maintenanceList/:id': {
    title: '工单列表',
    rightText: '',
    rightType: 'text',
    actionType: 'search'
  },
  '/touristslayout/maintenanceOrderDetail/:id': {
    title: '工单详情',
    rightText: '日志',
    rightType: 'text',
    actionType: 'search'
  },
  // 员工
  '/employeeslayout/elevatorMaintenance/:id': {
    title: '电梯维修',
    rightText: '日志',
    rightType: 'text',
    actionType: 'search'
  },
  // 员工
  '/employeeslayout/annualInspectionDue/:id': {
    title: '年检到期',
    rightText: '',
    rightType: '',
    actionType: ''
  },
  // 员工
  '/employeeslayout/annualInspectionAndOverhaul/:id': {
    title: '年检大修',
    rightText: '',
    rightType: '',
    actionType: ''
  },
  '/touristslayout/log/:id': {
    title: '维修日志',
    rightText: '',
    rightType: '',
    actionType: ''
  },
  '/employeeslayout/myLiftDetail/:id': {
    title: '电梯详情',
    rightText: '纠错',
    rightType: 'text',
    actionType: 'search'
  },
  '/employeeslayout/ercode/:id': {
    title: '二维码',
    rightText: '',
    rightType: '',
    actionType: ''
  },
  '/employeeslayout/liftLocation/:id': {
    title: '电梯定位',
    rightText: '',
    rightType: 'text',
    actionType: 'search'
  },
  '/employeeslayout/document/:id': {
    title: '技术文档',
    rightText: '',
    rightType: '',
    actionType: ''
  },
  '/employeeslayout/documentList': {
    title: '技术文档',
    rightText: '搜索',
    rightType: 'text',
    actionType: 'search'
  },
  '/employeeslayout/documentListTwo/:id': {
    title: '技术文档',
    rightText: '搜索',
    rightType: 'text',
    actionType: 'search'
  },
}


export default function CommonR(state = commonState, action) {
  console.log(action)
  switch (action.type) {
    case commonAction.setCommontName:
      return {
        ...state,
        componentName: action.componentName,
      }
    case commonAction.setHeaderConfig:
      return {
        ...state,
        headerConfig: { ...action.headerConfig },
      }
    case commonAction.setPathname:
      return {
        ...state,
        pathname: action.pathname,
      }
    default:
      return state;
  }
}