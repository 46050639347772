/**
 * 根据swiper 写的周日历组件
 */

import React from 'react'
import './WeeklyCalendar.scss'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';

const weeks = ['周日', '周一', '周二', '周三', '周四', '周五', '周六']
class WeeklyCalendar extends React.Component {
  slideIndex = 0
  nowWeek = new Date().getDay()
  constructor(props) {
    super(props);
    this.state = {
      weeks: [],
      dateArray: [],//计算出来的日期数组
      arr: [],
      currentMs: 0,
      date: ''

    }
    if (this.props.weeks && Array.isArray(this.props.weeks) && this.props.weeks.length === 7) {
      this.state.weeks = this.props.weeks
    } else {
      this.state.weeks = weeks
    }
    this.initDate()
  }
  format = (num) => {
    let str = num >= 10 ? num : '0' + num
    return str
  }

  // 初始化时候根据传入的值生成日期  index负数为前几周 正数 后几周 n为负数代表过去的日子 正数代表以后的日子
  countTime = (n, index) => {
    let needTime = this.initMs + (n + index * 7) * (24 * 60 * 60 * 1000)
    return this.formatting(needTime)
  }
  // 根据毫秒数获取获取未来七天的数组
  createDateByMillisecondsForFuture(ms) {
    let arr = []
    for (let i = 1; i <= 7; i++) {
      let needMs = ms + i * 24 * 60 * 60 * 1000
      arr.push(this.formatting(needMs))
    }
    return arr
  }
  // 根据毫秒数获取获取未来七天的数组
  createDateByMillisecondsForPast(ms) {
    let arr = []
    for (let i = -1; i >= -7; i--) {
      let needMs = ms + i * 24 * 60 * 60 * 1000
      arr.unshift(this.formatting(needMs))
    }
    return arr
  }

  formatting(needTime) {
    let needDate = new Date(needTime)
    let needYear = needDate.getFullYear()
    let needMonth = this.format(needDate.getMonth() + 1)
    let needDay = this.format(needDate.getDate())
    let needWeek = needDate.getDay()
    let timeObj = {
      year: needYear,
      month: needMonth,
      week: needWeek,
      day: needDay,
      date: `${needYear}-${needMonth}-${needDay}`,
      ms: needTime
    }
    return timeObj
  }

  createDate = () => {
    let dateArray = []
    for (let j = -1; j < 2; j++) {
      let dateChildArray = []

      if (this.nowWeek === 7) {
        dateChildArray.push(this.countTime(0, j))
        for (let i = 1; i < 7; i++) {
          dateChildArray.push(this.countTime(i, j))
        }
      } else {
        for (let i = -this.nowWeek; i < 0; i++) {
          dateChildArray.push(this.countTime(i, j))
        }
        dateChildArray.push(this.countTime(0, j))
        for (let i = 1; i < 7 - this.nowWeek; i++) {
          dateChildArray.push(this.countTime(i, j))
        }
      }
      dateArray.push(dateChildArray)

    }
    return dateArray
  }
  // 初始化当前日期数组
  initDate = () => {
    let date;
    if (this.props.initialDate) {
      date = new Date(this.props.initialDate)
    } else {
      date = new Date()
    }
    this.nowWeek = date.getDay()
    this.initMs = date.getTime()
    this.date = this.formatting(this.initMs).date
    this.dateArray = this.createDate()
  }

  componentDidMount() {
    this.setState({
      dateArray: this.dateArray,
      arr: this.arr,
      currentMs: this.initMs,
      date: this.date
    })
  }



  slideChange = (e) => {


  }
  onSwiper = (e) => {
    this.realIndex = e.realIndex
  }
  chooseDate(it) {
    this.initMs = it.ms
    this.setState({
      currentMs: this.initMs,
      date: it.date
    })
    if (this.props.getDate) {
      this.props.getDate(it)
    }
    
  }
  slidePrevTransitionStart = (e) => {
    console.log(e.realIndex)
    if (e.realIndex === 2) {
      this.arr[1] = this.arr[2] - 1
      this.dateArray[1] = this.createDateByMillisecondsForPast(this.dateArray[2][0].ms)
    } else if (e.realIndex === 1) {
      this.arr[0] = this.arr[1] - 1
      this.dateArray[0] = this.createDateByMillisecondsForPast(this.dateArray[1][0].ms)
    } else {
      this.arr[2] = this.arr[0] - 1
      this.dateArray[2] = this.createDateByMillisecondsForPast(this.dateArray[0][0].ms)
    }
    this.setState({
      arr: this.arr
    })
  }
  arr = [1, 2, 3]
  slideNextTransitionStart = (e) => {
    if (e.realIndex === 2) {
      this.dateArray[0] = this.createDateByMillisecondsForFuture(this.dateArray[2][6].ms)
    } else if (e.realIndex === 0) {
      this.dateArray[1] = this.createDateByMillisecondsForFuture(this.dateArray[0][6].ms)
    } else {
      this.dateArray[2] = this.createDateByMillisecondsForFuture(this.dateArray[1][6].ms)
    }
    if (e.realIndex === 0) {
      this.arr[2] = this.arr[1] + 1
    }
    if (e.realIndex === 2) {
      this.arr[0] = this.arr[2] + 1
    }
    if (e.realIndex === 0) {
      this.arr[1] = this.arr[0] + 1
    }
    if (e.realIndex === 1) {
      this.arr[2] = this.arr[1] + 1
    }
    this.setState({
      arr: this.arr
    })
  }










  render() {
    let arr = this.state.dateArray
    if (!arr.length) {
      return <div />
    }
    return (
      <div className="weeklyCalendarBox">
        <div className="weeklyCalendarBox-header">
          <ul>
            {this.state.weeks.map((item, index) => {
              return <li key={index}>{item}</li>
            })}
          </ul>
        </div>
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          initialSlide={1}
          onSlideChange={(e) => this.slideChange(e)}
          onSwiper={(swiper) => this.onSwiper(swiper)}
          loop={true}
          onSlidePrevTransitionStart={(e) => this.slidePrevTransitionStart(e)}
          onSlideNextTransitionStart={(e) => this.slideNextTransitionStart(e)}
        >


          {arr.map((item, index) => {
            return <SwiperSlide key={index}>
              <div className="weeklyCalendarBox-header weeklyCalendarBox-body">
                <ul>
                  {
                    item.map((it) => {
                      return <li key={it.date} onClick={() => this.chooseDate(it)}>
                        <span className={this.state.date === it.date ? "weeklyCalendarBox-item-active" : ''}>{it.day}</span>
                      </li>
                    })
                  }
                </ul>
              </div>
            </SwiperSlide>
          })}
          {/* {arr.map((item, index) => {
            return <SwiperSlide key={index}>
              {item}
            </SwiperSlide>
          })} */}
        </Swiper>

      </div>
    )
  }
}

export default WeeklyCalendar;