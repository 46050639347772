import React from 'react'
import './MyLiftDetail.scss'
import commonAction from '../../actions/common'
import { connect } from 'react-redux';
import { getLiftDetail } from '../../api/MyLiftDetail'
class MyLiftDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      detail: {}
    }
  }
  componentWillMount() {
    console.log(this.props.path)
    this.props.dispatch({ type: commonAction.setPathname, pathname: this.props.path })
    this.props.onRef(this);
    this.getLiftDetailFun(this.props.match.params.id * 1)
  }
  getLiftDetailFun = async (liftId) => {
    const res = await getLiftDetail({ liftId });
    if (res.code === 200) {
      this.setState({
        detail: res.data
      })
    }
  }
  submit = () => {
    this.props.history.push({ pathname: `/layout/myliftedit/${this.props.match.params.id * 1}` });
  }
  render() {
    const detail = this.state.detail
    return (
      <div className="commonBodyBox myLiftDetailBox">
        <div>
          <h3>电梯信息</h3>
          <ul>
            <li>
              <span>所属项目</span>
              <span>{detail.projectName}</span>
            </li>
            <li>
              <span>注册代码</span>
              <span>{detail.registerCode}</span>
            </li>
            <li>
              <span>电梯位置</span>
              <span>{detail.liftLocation}</span>
            </li>
            <li>
              <span>设备编号</span>
              <span>{detail.equipmentCode}</span>
            </li>
            <li>
              <span>保养单</span>
              <span>{detail.maintenanceOrderName}</span>
            </li>
            <li>
              <span>电梯品牌</span>
              <span>{detail.liftBrand}</span>
            </li>
            <li>
              <span>电梯类型</span>
              <span>{detail.liftTypeName}</span>
            </li>
          </ul>
          <div className="coverBox"></div>
          <h3>日期信息</h3>
          <ul>
            <li>
              <span>制造日期</span>
              <span>{detail.manufactureDate}</span>
            </li>
            <li>
              <span>检验日期</span>
              <span>{detail.annualInspectionDate}</span>
            </li>
          </ul>
          <div className="coverBox"></div>
          <h3>单位信息</h3>
          <ul>
            <li>
              <span>制造单位</span>
              <span>{detail.manufactureCompany}</span>
            </li>
            <li>
              <span>检验单位</span>
              <span>{detail.inspectionUnit}</span>
            </li>
            <li>
              <span>使用单位</span>
              <span>{detail.propertyUnitName}</span>
            </li>
            <li>
              <span>单位联系人</span>
              <span>{detail.propertyUnitContact}</span>
            </li>
            <li>
              <span>联系人电话</span>
              <span>{detail.propertyUnitContactPhone}</span>
            </li>
          </ul>
        </div>
      </div>
    )
  }

}



export default connect()(MyLiftDetail);