import React from 'react';
import './home.scss'
import Bander from '../../static/images/banner.png'
import Saoma from '../../static/images/saoma.png'
import Dianti from '../../static/images/dianti.png'
import Caiji from '../../static/images/caiji.png'
import Baoyang from '../../static/images/baoyang.png'
import Tongji from '../../static/images/tongji.png'
import Jihua from '../../static/images/jihua.png'
import { getWechatConfig, wxValidationPermissions, getPermissions } from '../../api/Sys'
import wx from 'weixin-js-sdk'
import { Toast } from 'antd-mobile';

const list = [
  {
    text: '扫码',
    url: '/layout/scanCode',
    fun: '',
    icon: Saoma,
    type: 'scanCode',
  },
  {
    text: '我的电梯',
    url: '/layout/mylift',
    fun: '',
    icon: Dianti,
    type: 'jump'
  },
  {
    text: '采集',
    url: '/layout/collect',
    fun: '',
    icon: Caiji,
    type: 'jump'
  },
  {
    text: '保养',
    url: '/layout/maintenanceList',
    fun: '',
    icon: Baoyang,
    type: 'jump'
  },
  {
    text: '统计',
    url: '/layout/statistical',
    fun: '',
    icon: Tongji,
    type: 'jump'
  },
  {
    text: '维保日历',
    url: '/layout/calendar',
    fun: '',
    icon: Jihua,
    type: 'jump'
  },
  {
    text: '任务指派',
    url: '/layout/mylift2',
    fun: '',
    icon: Dianti,
    type: 'jump'
  },
  {
    text: '技术文档',
    url: '/employeeslayout/documentList',
    fun: '',
    icon: Tongji,
    type: 'jump'
  },
  {
    text: '待签字',
    url: '/layout/toBeSigned',
    fun: '',
    icon: Tongji,
    type: 'jump'
  }
];

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      list: []
    }
  }
  componentWillMount() {
    this.getPermissionsFun()
  }
  componentDidMount() {
    this.getWechatConfigFun()
  }
  // 判断是不是ios
  isIos = () => {
    const u = navigator.userAgent;
    return u.indexOf("iPhone") > -1 || u.indexOf("Mac OS") > -1;
  }
  // 获取授权
  getWechatConfigFun = async () => {
    let url = window.location.href.split("#")[0];
    if (this.isIos()) {
      url = localStorage.getItem('url')
    }
    const res = await getWechatConfig(url)
    if (res.code === 200) {
      const data = { ...res.data }
      data.jsApiList = ['scanQRCode', 'getLocation']
      wxValidationPermissions(data)
    }
  }
  getPermissionsFun = async () => {
    const res = await getPermissions()
    let arr = []
    if (res.code === 200) {
      res.data.forEach((item) => {
        if (item.attributes.menuShow) {
          arr.push(item.attributes.menuUrl)
        }
      })
      let showList = []
      list.forEach((item) => {
        if (arr.indexOf(item.url) >= 0) {
          showList.push(item)
        }
      })
      this.setState({
        list: showList
      })
      console.log(showList)
    }
  }
  jump = (item) => {
    if (item.type === 'jump') {
      this.props.history.push(item.url)
    } else if (item.type === 'scanCode') {
      this.scanQRCode()
    }
    // this.props.history.push(item.url)
  }
  scanQRCode = () => {
    const that = this
    wx.ready(() => {
      wx.checkJsApi({
        jsApiList: ["scanQRCode"],
        success(res) {
          if (res.checkResult.scanQRCode === false) {
            const message =
              "你的微信版本太低，不支持微信JS接口，请升级到最新的微信版本！";
            console.log(message);
          } else {
            wx.scanQRCode({
              needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
              scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
              success: function (res) {
                var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
                const liftCode = result.split(':')[1]
                // if (num % 2 === 0) {
                //   that.props.history.push({ pathname: `/tourists/${liftCode}` });
                // } else {
                //   that.props.history.push({ pathname: `/employees/${liftCode}` });
                // }
                that.props.history.push({ pathname: `/employees/${liftCode}` });

              },

            });
          }
        },
        fail(res) {
          const message = "checkJsApi fail=" + JSON.stringify(res);
          Toast.fail(message, 3)
          console.log(message, "2222222");
        },
      });

    });
    wx.error(function (res) {
      console.log(res, 'res-----home')
    })

  }
  getLocation = () => {
    console.log('location')
    wx.ready(() => {
      console.log("初始化js-sdk成功");
      wx.checkJsApi({
        jsApiList: ["getLocation"],
        success(res) {
          if (res.checkResult.getLocation === false) {
            const message =
              "你的微信版本太低，不支持微信JS接口，请升级到最新的微信版本！";
            console.log(message);
          } else {
            const message = "js-sdk检查成功";
            wx.getLocation({
              type: "wgs84", // 默认为wgs84的 gps 坐标，如果要返回直接给 openLocation 用的火星坐标，可传入'gcj02'
              success: function (res) {
                console.log(res);
                var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
                var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
                var speed = res.speed; // 速度，以米/每秒计
                var accuracy = res.accuracy; // 位置精度
              },
            });
          }
        },
        fail(res) {
          const message = "checkJsApi fail=" + JSON.stringify(res);
          console.log(message, "2222222");
        },
      });

    });
    wx.error(function (res) {
      console.log(res, '----')
    })
  };
  render() {
    const nodes = this.state.list.map((item, index) => {
      return (
        <li key={index} onClick={() => this.jump(item)}>
          <img src={item.icon} alt="" />
          <span>{item.text}</span>
        </li>
      )
    })
    return (
      <div className="HomeBox">
        <div className="headerBox">
          <img src={Bander} alt="" onClick={this.getLocation} />
        </div>
        <div>
          <ul>
            {nodes}
            <li></li>
          </ul>
        </div>
      </div>
    )
  }
}


export default Home;