/**
 * 公共的header
 */
import React from 'react';
import { NavBar } from 'antd-mobile';
import './header.scss'
import leftIcon from '../../static/icons/leftIcon.png'


// function Header(props) {
//   function back() {
//     console.log(props)
//    props.history.goBack()
//   }
//   function rightClick() {
//     props.onRightClick()
//   }
//   return (
//     <div className="headerBox">
//       <NavBar
//         mode="light"
//         icon={<img src={leftIcon} alt="" type="left" />}
//         onLeftClick={back}
//         rightContent={[
//           props.rightText ? <span key="0" className="rightSpan" onClick={rightClick}>{props.rightText}</span> : ''
//         ]}
//       >{props.title}</NavBar>
//     </div>
//   )
// }

class Header extends React.Component {

  back = () => {
    this.props.back()
  }
  rightClick = () => {
    this.props.onRightClick()
  }
  render() {
    return (
      <div className="headerBox">
        <NavBar
          mode="light"
          icon={<img src={leftIcon} alt="" type="left" />}
          onLeftClick={this.back}
          rightContent={[
            this.props.rightText ? <span key="0" className="rightSpan" onClick={this.rightClick}>{this.props.rightText}</span> : ''
          ]}
        >{this.props.title}</NavBar>
      </div>
    )
  }
}


export  default Header;