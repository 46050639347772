import React, { Component } from 'react';
import './index.scss';
import { connect } from 'react-redux';
import { getDictionaryList } from '../../api/Sys'
import ScrollBox from '../../components/ScrollBox'
import Drawer from '../../components/Drawer'
import { InputItem, Button } from 'antd-mobile';
import Lodding from '../../components/Lodding'
import NoData from '@/components/NoData'
import fileIcon from '../../../src/static/images/file.jpeg'
import commonAction from '../../actions/common'
class MyLift extends Component {
  constructor(props) {
    super(props);

    this.state = {
      list: [],
      isCanLoading: false,
      scrollHight: 0,
      open: false,
      keyword: '',
      animating: false
    };

    this.keyword = "";
    this.timmer = null;
    this.dictionaryList = []
  }
  // POP代表后退回来的 所以要显示缓存的各种数据
  componentDidMount() {
    const pathname = this.props.path;
    this.props.dispatch({ type: commonAction.setPathname, pathname })
    this.props.onRef(this);
    this.handleRefresh()

  }

  // 获取电梯类型
  getDictionaryListFun = async () => {
    const res = await getDictionaryList({ dictionaryClass: 'JSWDLX' })
    if (res.code === 200) {
      this.setState({
        list: res.data
      })
      this.dictionaryList = JSON.parse(JSON.stringify(res.data))
    }
  }



  // 上拉加载更多
  handleScroll = () => {

  }
  goDocuments = (dictionaryCode) => {
    // this.props.history.push({ pathname: `/layout/myliftdetail/${val.liftId}` });
    this.props.history.push({ pathname: `/employeeslayout/documentListTwo/${dictionaryCode}` });
  }

  handleClose = () => {
    this.setState({
      open: false
    })
  }

  search = () => {

    const arr = this.dictionaryList.filter(item => item.dictionaryValue.includes(this.keyword))
    this.setState({
      list: arr,
      open: false
    })

  }

  inputChange = (val) => {
    this.keyword = val
    this.setState({
      keyword: val
    })
  }
  // 缓存现在滚动的位置
  setScrollHight = (val) => {
    console.log(val)

  }
  // 刷新
  handleRefresh = () => {

    this.keyword = ''
    this.setState({
      keyword: '',
      list: []
    })
    this.getDictionaryListFun();
  }

  submit = () => {
    this.setState({
      open: true
    })
    this.timmer = setTimeout(() => {
      if (this.inputRef) {
        this.inputRef.focus();
      }

    }, 1000);
    return this.state.name
  }


  componentWillUnmount() {
    if (this.timmer) {
      clearTimeout(this.props.timmer)
    }
  }




  render() {
    return (
      <div className="documentListBox commonBodyBox">
        <div className="documentListBoxScrollBox">
          {
            this.state.list && this.state.list.length ? (<ScrollBox setScrollHight={this.setScrollHight} handleRefresh={this.handleRefresh} handleScroll={this.handleScroll} isCanLoading={this.state.isCanLoading} scrollHight={this.state.scrollHight}>
              <ul className="documentListBoxScrollBox-ul">
                {
                  this.state.list.map(item => <li key={item.id} onClick={() => this.goDocuments(item.dictionaryCode)}>
                    <img src={fileIcon} alt="" />
                    <span>{item.dictionaryValue}</span>
                  </li>)
                }
                <li></li>
              </ul>
            </ScrollBox>) : <NoData />
          }

        </div>
        <div>
          <Drawer direction="bottom" handleClose={this.handleClose} open={this.state.open}>
            <div className="searchBox">
              <InputItem
                className="searchInput"
                onChange={this.inputChange}
                clear
                placeholder="请输入名称"
                value={this.state.keyword}
                ref={el => this.inputRef = el}
              ></InputItem>
              <Button type="primary" onClick={this.search}>搜索</Button>
            </div>
          </Drawer>
        </div>
        <Lodding animating={this.state.animating} />
      </div>
    );
  }
}




export default connect()(MyLift);