import { fetch } from '../../plugin/axios'
const serverName = '/maintain'

/**
 * 获取工单日志
 * @param {*} params 
 */
export function getOrderLogInfo(params) {
  return fetch({
    url: serverName + '/app/maintain/order/logInfo',
		method: 'GET',
		params
  })
}