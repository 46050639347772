import Lodding from "../../components/Lodding";
import NoData from "@/components/NoData";
import commonAction from "../../actions/common";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {getLiftDetail} from "../../api/MyLiftDetail";
import {connect} from "react-redux";
import "./index.scss";
const TechnicalDocument = ({path, dispatch}) => {
	const params = useParams();
	const [animating, setAnimating] = useState(true);
	const [technicalDocumentList, setTechnicalDocumentList] = useState([]);
	const getLiftDetailFun = async (liftId) => {
		const res = await getLiftDetail({liftId: params.id});
		setAnimating(false);
		if (res.code === 200) {
			setTechnicalDocumentList(res.data.technicalDocumentList || []);
		}
	};

	useEffect(() => {
		const pathname = path;
		dispatch({type: commonAction.setPathname, pathname});
		getLiftDetailFun();
	}, []);

	return (
		<div className="technicalDocument commonBodyBox">
			<div className="technicalDocumentBox">
				{technicalDocumentList && technicalDocumentList.length ? (
					technicalDocumentList.map((item) => (
						<div key={item.id}>
							<h3>品牌：{item.brand}</h3>
							<h3>型号：{item.model}</h3>
							<h3>说明：{item.content}</h3>
							<h3>资源</h3>
							<ul>
								{item.documentItemList && item.documentItemList.length
									? item.documentItemList.map((it) => (
											<li key={it.id}>
												<a href={it.resourcesPath}>
													{it.description || it.resourcesName}
												</a>
											</li>
									  ))
									: null}
							</ul>
						</div>
					))
				) : (
					<NoData />
				)}
			</div>

			<Lodding animating={animating} />
		</div>
	);
};

export default connect()(TechnicalDocument);
