//为了实现缓存功能 将我的电梯页面的参数以及scroll的位置信息存储下来 方便从详情页面返回回来的时候呈现


const myLiftState = {
  pageIndex: 1,
  keword: "",
  scrollHight: 0,
  list: [],
  totalPage: 0
}


export default function myLiftR(state = myLiftState, action) {
  switch (action.type) {
    case "setScrollHight":
      return {
        ...state,
        scrollHight: action.scrollHight,
      }
    case "setPageIndex":
      return {
        ...state,
        pageIndex: action.pageIndex,
      }
    case "setKeword":
      return {
        ...state,
        keword: action.keword,
      }
    case "setmyLiftData":
      return {
        ...state,
        list: action.payload.list,
        totalPage: action.payload.totalPage
      }
    default:
      return state;
  }
}