import React from 'react'
import './Select.scss'


// props label 代表 输入框要显示的值 valueKey 代表根据那个字段进行匹配  chooseData选择的值 list列表
class Select extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      inputValue: '',
      showList: false,
    }
    this.ban = false
    this.selectData = null
  }

  handleChange = (e) => {
    this.ban = true
    this.setState({
      inputValue: e.target.value
    })
    if (this.props.search) {
      this.props.search(e.target.value)
    }
  }
  handleFocus = () => {
    if (this.props.handleFocus) {
      this.props.handleFocus()
    }
    this.setState({
      showList: true
    })
  }
  handleBlur = () => {
    if (this.selectData) {
      this.setState({
        showList: false,
        inputValue: this.selectData[this.props.label]
      })
      return
    }
    this.setState({
      showList: false
    })
  }

  handleClick = (val) => {
    this.setState({
      inputValue: val[this.props.label]
    })
    this.selectData = val
    if (this.props.handleChoose) {
      this.props.handleChoose(val)
    }
  }
  // 为了初始化第一次
  componentWillReceiveProps(nextProps) {
    if (nextProps.chooseData && !this.ban) {
      this.setState({
        inputValue: nextProps.chooseData[nextProps.label]
      })
      this.selectData = nextProps.chooseData
    }
  }
  componentDidMount() {
    
  }

  
  render() {
    const { inputValue, showList } = this.state
    const { list, label, chooseData, valueKey } = this.props
    let ele;
    if (list.length) {
      ele = list.map((item, index) => {
        return (<li className={ chooseData && item[valueKey] === chooseData[valueKey] ? "chooseActive" : ''} onClick={()=> this.handleClick(item)} key={index}>{item[label]}</li>)
      })
    } else {
      ele = <span className="noData">暂无数据</span>
    }
    
    return (
      <div className="selectBox">
        <input type="text" value={inputValue} onChange={this.handleChange} onFocus={this.handleFocus}  onBlur={this.handleBlur}/>
        <div className="selectOptionBox">
          <ul className={showList ? "selectOptions" : ""}>
            {ele}
          </ul>
        </div>
      </div>
    )
  }
}


export default Select