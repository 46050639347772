import Cookies from 'js-cookie'

const cookies = {}
const version = "1.0.0"
const appName = "we-chat"
/**
 * @description 存储 cookie 值
 * @param {String} name cookie name
 * @param {String} value cookie value
 * @param {Object} setting cookie setting
 */
cookies.set = function(name = 'default', value = '', cookieSetting = {}) {
	let currentCookieSetting = {
		expires: 1
	}
	Object.assign(currentCookieSetting, cookieSetting)
	Cookies.set(`${appName}-${version}-${name}`, value, currentCookieSetting)
}

/**
 * @description 拿到 cookie 值
 * @param {String} name cookie name
 */
cookies.get = function(name = 'default') {
	return Cookies.get(`${appName}-${version}-${name}`)
}

/**
 * @description 拿到 cookie 全部的值
 */
cookies.getAll = function() {
	return Cookies.get()
}

/**
 * @description 删除 cookie
 * @param {String} name cookie name
 */
cookies.remove = function(name = 'default') {
	return Cookies.remove(`${appName}-${version}-${name}`)
}

export default cookies
