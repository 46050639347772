import axios from 'axios';
import { Toast } from 'antd-mobile';
import cookies from '../../libs/util.cookies';


axios.defaults.timeout = 60000;
axios.defaults.retry = 3;
axios.defaults.retryDelay = 2000;


function axiosRerty(err) {
  const config = err.config
  if (!config || !config.retry) {
    return Promise.reject(err)
  }
  config.__retryCount = config.__retryCount || 0
  if (config.__retryCount >= config.retry) {
    return Promise.reject(err)
  }
  config.__retryCount += 1

  const backoff = new Promise(function(resolve) {
    setTimeout(function() {
      resolve()
    }, config.retryDelay || 1)
  })
  return backoff.then(function() {
    return axios(config)
  })
}

axios.interceptors.request.use(
  (config) => {
    const token = cookies.get('token')
    if (process.env.NODE_ENV === 'production') {
      // 处理重复
      if (config.url.indexOf('/KYWB') !== 0) {
        config.url = '/KYWB' + config.url
      }
    }
    if (token) {
      config.headers = {
        ...config.headers,
        appCode: 'WYPT',
        Authorization: 'Bearer ' + token,
      }
    } else {
      config.headers = {
        ...config.headers,
        appCode: 'WYPT'
      }
    }
    if (config.url === "/auth/dictionary/getDictionaryList") {
      config.headers.appCode = "YHGHT"
    }
    return config
  },
  (error) => {
      return Promise.reject(error)
    }
  )
  
  axios.interceptors.response.use(async (res) => {
    if (res.data.code === 1004) {
      Toast.fail(res.data.message, 1);
      return res.data
    }
    if (res.data && res.data.errno === 0) {
      return res.data
    }
    if (res.data.code !== 200 && res.data.code !== 304) {
      if (res.data.code === 1009 || res.data.code === 1008) {
        return res.data
      }
      if (res.data.code === 1001 || res.data.code === 1002 || res.data.code === 1003) {
        cookies.remove('token')
        window.location = window.location.href.split('/')[0] + '/login'
        Toast.fail(res.data.message, 1);
        return res.data
      }
      // Vue.prototype.$message({
      //   type: 'error',
      //   message: res.data.message
      // })
      Toast.fail(res.data.message, 1);
    }
  
    return res.data
  }, axiosRerty)


  export {
    axios as fetch
  }

