import React, { Component } from 'react';
import './myLift.scss';
import commonAction from '../../actions/common'
import { connect } from 'react-redux';
import { getMyLiftList, getAssignLiftList } from '../../api/MyLift'
import ScrollBox from '../../components/ScrollBox'
import ItemBox from '../../components/ItemBox'
import Drawer from '../../components/Drawer'
import { InputItem, Button } from 'antd-mobile';
import Lodding from '../../components/Lodding'
import NoData from '@/components/NoData'
class MyLift extends Component {
  constructor(props) {
    super(props);

    this.state = {
      list: [],
      isCanLoading: true,
      scrollHight: 0,
      open: false,
      keyword: '',
      animating: false
    };
    this.pageIndex = 1;
    this.pageSize = 20;
    this.keyword = "";
    this.totalPage = 0;
  }
  // POP代表后退回来的 所以要显示缓存的各种数据
  componentDidMount() {
    const pathname = this.props.path;
    this.props.dispatch({ type: commonAction.setPathname, pathname })
    this.props.onRef(this);
    if (this.props.history.action === "POP") {
      this.pageIndex = this.props.myLiftState.pageIndex
      this.keyword = this.props.myLiftState.keyword
      this.totalPage = this.props.myLiftState.totalPage
      this.setState((state) => ({
        list: this.props.myLiftState.list,
        scrollHight: this.props.myLiftState.scrollHight
      }))
    } else {
      this.pageIndex = 1;
      this.handleRefresh()

    }

  }
  getMyLiftListFun = async () => {
    // this.setState({
    //   animating: true
    // })
    const params = {
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      keyword: this.keyword
    }
    let getFun = null;
    if (this.props.path=="/layout/mylift2") {
      getFun = getAssignLiftList;
    } else {
      getFun = getMyLiftList
    }
    
    const res = await getFun(params);
    if (res.code === 200 && res.data.list) {
      this.props.dispatch({ type: "setPageIndex", pageIndex: this.pageIndex })
      this.props.dispatch({ type: "setKeword", keword: this.keword })
      this.totalPage = res.data.totalPage;
      this.setState((state) => ({
        list: state.list.concat(res.data.list),
        isCanLoading: true,
        animating: false
      }))
      this.props.dispatch({ type: "setmyLiftData", payload: { list: this.state.list, totalPage: res.data.totalPage } })
    }
  }
  submit = () => {
    this.setState({
      open: true
    })
    this.timmer = setTimeout(() => {
      if (this.inputRef) {
        this.inputRef.focus();
      }

    }, 1000);
    return this.state.name
  }

  // 上拉加载更多
  handleScroll = () => {
    this.setState({
      isCanLoading: false
    })
    if (this.pageIndex < this.totalPage) {
      this.pageIndex += 1
      this.getMyLiftListFun()
    }
  }
  handleClick = (val) => {
    const liftCode = val.systemCode;
    // this.props.history.push({ pathname: `/layout/myliftdetail/${val.liftId}` });
    this.props.history.push({ pathname: `/liftemployees/${liftCode}` });
  }

  handleClose = () => {
    this.setState({
      open: false
    })
  }

  search = () => {
    this.setState({
      list: [],
      open: false
    })
    this.pageIndex = 1;
    this.getMyLiftListFun()
  }

  inputChange = (val) => {
    this.keyword = val
    this.setState({
      keyword: val
    })
  }
  // 缓存现在滚动的位置
  setScrollHight = (val) => {
    console.log(val)
    this.props.dispatch({ type: "setScrollHight", scrollHight: val })
  }
  // 刷新
  handleRefresh = () => {
    this.pageIndex = 1
    this.totalPage = 0
    this.keyword = ''
    this.setState({
      keyword: '',
      scrollHight: 0,
      list: []
    })
    this.setScrollHight(0)
    this.getMyLiftListFun();
  }

  componentWillUnmount() {
    if (this.timmer) {
      clearTimeout(this.props.timmer)
    }
  }

  render() {
    return (
      <div className="myLiftBox commonBodyBox">
        <div className="myLiftBoxScrollBox">
          {
            this.state.list && this.state.list.length ? (<ScrollBox setScrollHight={this.setScrollHight} handleRefresh={this.handleRefresh} handleScroll={this.handleScroll} isCanLoading={this.state.isCanLoading} scrollHight={this.state.scrollHight}>
              <ItemBox handleClick={this.handleClick} list={this.state.list} type="myLift" />
            </ScrollBox>) : <NoData /> 
          }

        </div>
        <div>
          <Drawer direction="bottom" handleClose={this.handleClose} open={this.state.open}>
            <div className="searchBox">
              <InputItem
                className="searchInput"
                onChange={this.inputChange}
                clear
                placeholder="请输入名称"
                value={this.state.keyword}
                ref={el => this.inputRef = el}
              ></InputItem>
              <Button type="primary" onClick={this.search}>搜索</Button>
            </div>
          </Drawer>
        </div>
        <Lodding animating={this.state.animating} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    myLiftState: state.myLiftR
  }
}


export default connect(mapStateToProps)(MyLift);