import React from 'react'
import './Dialog.scss'

class Dialog extends React.Component {
  constructor(props) {
    super(props)
  }
  handleCancle = () => {
    if (this.props.handleCancle) {
      this.props.handleCancle()
    }
  }
  handleConfirm = () => {
    if (this.props.handleConfirm) {
      this.props.handleConfirm()
    }
  }
  render() {
    const { title, content } = this.props
    return (
      <div className="dialogBox">
        <div className="main">
          <h1>{title || '提示'}</h1>
          <p>{content || ''}</p>
          <div className="dialog-foot">
            <div onClick={this.handleCancle}>取消</div>
            <div onClick={this.handleConfirm}>确定</div>
          </div>
        </div>
      </div>
    )
  }
}

export default Dialog