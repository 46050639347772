import React, { Component } from 'react';
import './documentListTwo.scss';
import commonAction from '../../actions/common'
import { connect } from 'react-redux';
import { getDocumentListTwoList } from '../../api/DocumentListTwo'
import ScrollBox from '../../components/ScrollBox'
import ItemBox from '../../components/ItemBox'
import Drawer from '../../components/Drawer'
import { InputItem, Button } from 'antd-mobile';
import Lodding from '../../components/Lodding'
import NoData from '@/components/NoData'
class DocumentListTwo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      list: [],
      isCanLoading: true,
      scrollHight: 0,
      open: false,
      keyword: '',
      animating: false
    };
    this.pageIndex = 1;
    this.pageSize = 20;
    this.keyword = "";
    this.totalPage = 0;
  }
  // POP代表后退回来的 所以要显示缓存的各种数据
  componentDidMount() {

    this.documentTypeCode = this.props.match.params.id;
    const pathname = this.props.path;
    this.props.dispatch({ type: commonAction.setPathname, pathname })
    this.props.onRef(this);
    if (this.props.history.action === "POP") {
      this.pageIndex = this.props.documentListTwoState.pageIndex
      this.keyword = this.props.documentListTwoState.keyword
      this.totalPage = this.props.documentListTwoState.totalPage
      this.setState((state) => ({
        list: this.props.documentListTwoState.list,
        scrollHight: this.props.documentListTwoState.scrollHight
      }))
      if (!this.props.documentListTwoState.list.length) {
        this.pageIndex = 1;
        this.handleRefresh()
      }
    } else {
      this.pageIndex = 1;
      this.handleRefresh()

    }

  }
  getDocumentListTwoListFun = async () => {
    // this.setState({
    //   animating: true
    // })
    const params = {
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      keyword: this.keyword,
      documentTypeCode: this.documentTypeCode,
    }

    const res = await getDocumentListTwoList(params);
    if (res.code === 200 && res.data.list) {
      this.props.dispatch({ type: "setPageIndex", pageIndex: this.pageIndex })
      this.props.dispatch({ type: "setKeword", keword: this.keword })
      this.totalPage = res.data.totalPage;
      this.setState((state) => ({
        list: state.list.concat(res.data.list),
        isCanLoading: true,
        animating: false
      }))
      this.props.dispatch({ type: "setdocumentListTwoData", payload: { list: this.state.list, totalPage: res.data.totalPage } })
    }
  }
  submit = () => {
    this.setState({
      open: true
    })
    this.timmer = setTimeout(() => {
      if (this.inputRef) {
        this.inputRef.focus();
      }

    }, 1000);
    return this.state.name
  }

  // 上拉加载更多
  handleScroll = () => {
    this.setState({
      isCanLoading: false
    })
    if (this.pageIndex < this.totalPage) {
      this.pageIndex += 1
      this.getDocumentListTwoListFun()
    }
  }
  handleClick = (val) => {
    const liftCode = val.systemCode;
    // this.props.history.push({ pathname: `/layout/myliftdetail/${val.liftId}` });
    this.props.history.push({ pathname: `/liftemployees/${liftCode}` });
  }

  handleClose = () => {
    this.setState({
      open: false
    })
  }

  search = () => {
    this.setState({
      list: [],
      open: false
    })
    this.pageIndex = 1;
    this.getDocumentListTwoListFun()
  }

  inputChange = (val) => {
    this.keyword = val
    this.setState({
      keyword: val
    })
  }
  // 缓存现在滚动的位置
  setScrollHight = (val) => {
    console.log(val)
    this.props.dispatch({ type: "setScrollHight", scrollHight: val })
  }
  // 刷新
  handleRefresh = () => {
    this.pageIndex = 1
    this.totalPage = 0
    this.keyword = ''
    this.setState({
      keyword: '',
      scrollHight: 0,
      list: []
    })
    this.setScrollHight(0)
    this.getDocumentListTwoListFun();
  }

  componentWillUnmount() {
    if (this.timmer) {
      clearTimeout(this.props.timmer)
    }
  }

  render() {
    return (
      <div className="documentListTwoBox commonBodyBox">
        <div className="documentListTwoBoxScrollBox">
          {
            this.state.list && this.state.list.length ? (<ScrollBox setScrollHight={this.setScrollHight} handleRefresh={this.handleRefresh} handleScroll={this.handleScroll} isCanLoading={this.state.isCanLoading} scrollHight={this.state.scrollHight}>
              <div className="documentListTwoBox-main">
                {
                  this.state.list.map((item) => (
                    <div key={item.id}>
                      <h3>品牌：{item.brand}</h3>
                      <h3>型号：{item.model}</h3>
                      <h3>说明：{item.content}</h3>
                      <h3>资源</h3>
                      <ul>
                        {item.documentItemList && item.documentItemList.length
                          ? item.documentItemList.map((it) => (
                            <li key={it.id}>
                              <a href={it.resourcesPath}>
                                {it.description || it.resourcesName}
                              </a>
                            </li>
                          ))
                          : null}
                      </ul>
                    </div>
                  ))
                }
              </div>
            </ScrollBox>) : <NoData />
          }

        </div>
        <div>
          <Drawer direction="bottom" handleClose={this.handleClose} open={this.state.open}>
            <div className="searchBox">
              <InputItem
                className="searchInput"
                onChange={this.inputChange}
                clear
                placeholder="请输入名称"
                value={this.state.keyword}
                ref={el => this.inputRef = el}
              ></InputItem>
              <Button type="primary" onClick={this.search}>搜索</Button>
            </div>
          </Drawer>
        </div>
        <Lodding animating={this.state.animating} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    documentListTwoState: state.documentListTwoR
  }
}


export default connect(mapStateToProps)(DocumentListTwo);