import { fetch } from '../../plugin/axios'
const serverName = '/maintain'

// 维保待客户签字列表
export function getMyLiftList(data) {
  return fetch({
    url: serverName + '/app/maintain/order/waitSignPageList',
		method: 'POST',
		data
  })
}

// 客户签字
export function customerSign(data) {
  return fetch({
    url: serverName + '/app/maintain/order/customerSign',
		method: 'POST',
		data
  })
}

