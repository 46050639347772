import React from 'react'
import { ActivityIndicator } from 'antd-mobile';

class Lodding extends React.Component {
  render() {
    return (
      <ActivityIndicator
        toast
        text="Loading..."
        animating={this.props.animating}
      />
    )
  }
}

export default Lodding