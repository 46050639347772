
import './App.scss';
import React from 'react'
import RouteConfigExample from './pages/RouteConfigExample'
function App(props) {
  return (
    <div className="appBox">
      <RouteConfigExample />
    </div>
  );
}

export default App;
