/**
 * 签名组件
 */

import React from 'react'
import SignatureCanvas from 'react-signature-canvas'
import './Signature.scss'
class Signature extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      url: ''
    }
  }
  handleClick = () => {
    const imgUrl = this.canvas.toDataURL("image/png");
    if (!this.canvas.isEmpty() && this.props.getSignature) {
      this.props.getSignature(imgUrl)
    }
    this.setState({
      url: imgUrl
    })
  }
  handleCancle = () => {
    this.canvas.clear()
  }
  render() {
    return (
      <div className="signatureBox">
        <div className="signatureBoxTitle">{this.props.title || '签名'}</div>
        <div>
          <SignatureCanvas penColor='green'
            ref={(ref) => {
              this.canvas = ref;
            }}
            canvasProps={{ width: 750, height: 500, className: 'sigCanvas' }} />
        </div>
        {/* <div>
          <img src={this.state.url} alt="" className="write-name-img"/>
        </div> */}
        <div className="signatureFooter">
          <span onClick={this.handleCancle}>重置</span>
          <span onClick={this.handleClick}>确定</span>
        </div>
      </div>

    )
  }
}

export default Signature