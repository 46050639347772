import React from 'react';

class ListItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      chooseIndex: 0
    }
  }
  componentDidMount() {
    this.setState({
      chooseIndex: this.props.item.result || 1
    })
  }

  handleClick = () => {
    if (this.props.status === 4) {
      return
    }
    this.chooseIndex = this.state.chooseIndex
    if (this.state.chooseIndex < 5) {
      this.chooseIndex += 1
    } else if (this.state.chooseIndex === 5) {
      this.chooseIndex = 1
    }
    this.setState({
      chooseIndex: this.chooseIndex
    })
    if (this.props.setNewData) {
      this.props.setNewData(this.chooseIndex, this.props.item)
    }
  }


  render() {
    const detail = this.props.item
    return (
      <div className="listItemBox">
        <div>
          <span>{detail.itemName}</span>
          <span>{detail.requirements}</span>
          <div onClick={this.handleClick}><i className={`active${this.state.chooseIndex}`}></i></div>
        </div>
      </div>
    )
  }
}

export default ListItem;