
import { takeEvery, put, call} from 'redux-saga/effects';
const api = () => new Promise((resolve, reject) => {
  setTimeout(() => {
    resolve()
  }, 1000)
})
function* addAsync(action) {
  yield call(api)
  yield put({ type: 'AddAsync', num: action.num})
}

function* addSaga() {
  yield takeEvery('AddAsyncSaga', addAsync)
}

export {
  addSaga
}