/**
 * 留白组件
 */

import React from 'react'

class WhiteSpace extends React.Component {
  render() {
    return (
      <div className="cover"></div>
    )
  }
}

export default WhiteSpace;