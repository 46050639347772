import { fetch } from '../../plugin/axios'
const serverName = '/maintain'

/**
 * 获取工单信息
 * @param {*} params 
 */
export function getOrderDetail(params) {
  return fetch({
    url: serverName + '/app/maintain/order/detail',
		method: 'GET',
		params
  })
}



/**
 * 保存工单
 * @param {*} data 
 */
export function saveOrder(data) {
  return fetch({
    url: serverName + '/app/maintain/order/save',
		method: 'POST',
		data
  })
}


/**
 * 开始工单
 * @param {*} data 
 */
export function startOrder(data) {
  return fetch({
    url: serverName + '/app/maintain/order/start',
		method: 'POST',
		data
  })
}

export function orderDistance(data) {
  return fetch({
    url: serverName + '/app/maintain/order/distance',
		method: 'POST',
		data
  })
}