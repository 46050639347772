
const maintenanceListState = {
  params: {
    pageIndex: 1,
    keyword: '',
    pageType: 1,
    pageSize: 20
  },
  totalPage: 0,
  // 列表数据
  list: [],
  scrollHight: 0
}

export default function touristsMaintenanceListR(state = maintenanceListState, action) {
  switch (action.type) {
    case "setScrollHight":
      return {
        ...state,
        scrollHight: action.scrollHight,
      }
    case "setMaintenanceListState":
      return {
        ...state,
        params: { ...action.payload.params },
        list: [...action.payload.list],
        totalPage: action.payload.totalPage
      }
    default:
      return state;
  }
}