import React from 'react'
import './ElevatorMaintenance.scss'
import { connect } from 'react-redux'
import commonAction from '@/actions/common'
import { Picker, List, ImagePicker, Toast } from 'antd-mobile';
import { getFaultReasonInfoSimpleList, getPointTree, getOrderLiftDetail, saveOrder, getOrderDetail } from '@/api/TryOutPage/ElevatorMaintenance';
import Drawer from '@/components/Drawer'
import Yes from '@/static/images/yes.png'
import Signature from '@/components/Signature'
import Lodding from '@/components/Lodding'
import { batchUploadFileByBase64, uploadFileByBase64, getWechatConfig, wxValidationPermissions } from '@/api/Sys'
import wx from 'weixin-js-sdk'
import { getLiftDetail } from '@/api/MyLiftDetail'
import transformFile from '@/libs/imageCompression'

class F extends React.Component {
  render() {

    let dom = ""
    const obj = { ...this.props.item }

    if (obj.childrenList && obj.childrenList.length) {
      dom = obj.childrenList.map((item) => {
        return <Ff key={item.id} item={item} pointChooseIdList={this.props.pointChooseIdList} handlePointClink={this.props.handlePointClink} />
      })
    }
    return (
      <div className="treeitem">
        <div className={this.props.pointChooseIdList.indexOf(obj.id) >= 0 ? 'treeItemName treeItemNameActive' : 'treeItemName'} onClick={() => { this.props.handlePointClink(obj) }}>
          <span>{obj.name}</span>
          {this.props.pointChooseIdList.indexOf(obj.id) >= 0 ? <img src={Yes} alt="" /> : ''}
        </div>
        {dom}
      </div>
    )
  }
}

function Ff({ item, handlePointClink, pointChooseIdList }) {
  return (<div className="group">
    <F item={item} key={item.key} handlePointClink={handlePointClink} pointChooseIdList={pointChooseIdList} />
  </div>)
}

const data = [{
  url: 'https://zos.alipayobjects.com/rmsportal/PZUUCKTRIHWiZSY.jpeg',
  id: '2121',
},];


function PointResult({ faultPointResultDTOList }) {
  if (faultPointResultDTOList) {
    const list = faultPointResultDTOList
    let dom = ""
    if (list && list.length) {
      dom = list.map((item, index) => {
        if (index === list.length - 1) {
          return <p key={item.index}>{(item.firstName && (item.secondName || item.thirdName)) ? (item.firstName + '-') : item.firstName}{item.secondName ? (item.thirdName ? (item.secondName + '-') : item.secondName) : ''}{item.thirdName ? item.thirdName : ''}</p>
        }
        return <p key={item.index}>{(item.firstName && (item.secondName || item.thirdName)) ? (item.firstName + '-') : item.firstName}{item.secondName ? (item.thirdName ? (item.secondName + '-') : item.secondName) : ''}{item.thirdName ? item.thirdName : ''},</p>
      })
    }
    return (
      <div>
        {dom}
      </div>
    )
  }

  return ''
}


class ElevatorMaintenance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      files: data,
      remark: '', //备注信息,
      faultDescription: '', //故障描述
      solution: '', //解决方案
      replacementParts: '', //更换配件信息,
      scenePhoto: [], //现场图片
      orderPhoto: [], //工单图片,
      faultReasonInfoSimpleList: [], //故障类型
      pointList: [], //故障点
      openPoint: false, //故障点弹窗,
      openSignature: false, //签名弹窗
      pointChooseIdList: [], //存储选中的故障点的key 传给子组建控制样式
      animating: false, //控制loding
      detail: {},
      liftDetail: {}, //电梯信息
      pointName: "", //选中的故障点名称
      status: '', //工单状态
      signatureImage: '' //签名
    }
    this.pointList = [] //存放选中的故障点
    this.pointId = [] //存放选中的故障点的id
    this.pointChooseIdList = [] //存储选中的故障点的key
    this.liftId = '' //电梯id
    this.repairOrderId = ''
    this.postData = {} //数据
    this.pointName = []
    this.orderDetail = {} //工单详情
    this.liftId = ''
    this.itemStyle = {
      'height': '60px',
    }
  }
  componentDidMount() {
    console.log(this.props)
    const pathname = this.props.path;
    this.props.dispatch({ type: commonAction.setPathname, pathname })
    this.props.onRef(this);
    this.liftId = this.props.match.params.id
    if (this.props.location.orderId) {
      this.orderId = this.props.location.orderId
      window.localStorage.setItem('orderId', this.props.location.orderId)
    }
    if (this.orderId && this.liftId) {
      this.getOrderDetailFun()
      this.getOrderLiftDetailFun()
    } else if (!this.props.location.orderId && this.props.match.params.id) {
      this.orderId = this.props.match.params.id
      this.getOrderDetailFun(true)
    }
    // this.getLiftDetailFun(this.liftId)
    // this.getOrderLiftDetailFun()
    // this.getOrderDetailFun()
    // this.repairOrderId = this.props.location.query.repairOrderId

    // this.getWechatConfigFun()
    // this.getFaultReasonInfoSimpleListFun();
    // this.getPointTreeFun();
  }

  // 判断是不是ios
  isIos = () => {
    const u = navigator.userAgent;
    return u.indexOf("iPhone") > -1 || u.indexOf("Mac OS") > -1;
  }
  // 为了获取repairOrderId
  getLiftDetailFun = async (liftId) => {
    const res = await getLiftDetail({ liftId });
    if (res.code === 200) {
      this.liftId = res.data.liftId
      this.getOrderLiftDetailFun()
      if (res.data.repairOrderId) {
        this.repairOrderId = res.data.repairOrderId

        this.getOrderDetailFun()
      }
    }
  }

  // 获取故障原因
  getFaultReasonInfoSimpleListFun = async () => {
    const res = await getFaultReasonInfoSimpleList({ size: 1000 })
    if (res.code === 200) {
      const arr = res.data.map(item => {
        return {
          value: item.id,
          label: item.faultReason
        }
      })
      this.setState({
        faultReasonInfoSimpleList: arr
      })
    }
  }

  // 获取故障点
  getPointTreeFun = async () => {
    const res = await getPointTree({
      pageIndex: 1,
      pageSize: 1000,
    })
    if (res.code === 200) {
      this.setState({
        pointList: res.data
      })
    }
  }

  // 获取工单中电梯信息
  getOrderLiftDetailFun = async () => {
    const res = await getOrderLiftDetail({ liftId: this.liftId });
    if (res.code === 200) {
      this.setState({
        liftDetail: res.data
      })
    }
  }

  //获取工单详情
  getOrderDetailFun = async (v) => {
    const res = await getOrderDetail({ orderId: this.orderId });
    if (res.code === 200) {
      this.orderDetail = res.data
      const orderDetail = res.data
      const { remark, replacementParts, solution, faultDescription } = orderDetail
      this.liftId = orderDetail.liftId;
      let liveImageDataList = []
      orderDetail.liveImageDataList.forEach((item, index) => {
        liveImageDataList.push({
          id: index,
          url: item.imageUrl
        })
      })
      let orderImageDataList = []
      orderDetail.orderImageDataList.forEach((item, index) => {
        orderImageDataList.push({
          id: index,
          url: item.imageUrl
        })
      })
      let signatureImage = ''
      if (orderDetail.signatureImageDataList && orderDetail.signatureImageDataList.length) {
        signatureImage = orderDetail.signatureImageDataList[0].imageUrl
      }
      this.setState({
        value: [orderDetail.faultReasonId],
        remark,
        replacementParts,
        solution,
        faultDescription,
        scenePhoto: liveImageDataList,
        orderPhoto: orderImageDataList,
        status: orderDetail.status,
        pointChooseIdList: orderDetail.faultPointIdList,
        signatureImage,
        faultPointResultDTOList: orderDetail.faultPointResultDTOList,
        faultReason: orderDetail.faultReason,
        currentUserName: orderDetail.currentUserName

      })
      this.pointChooseIdList = orderDetail.faultPointIdList
      this.setFaultPoint(this.state.pointList)
    }
    if (v) {
      this.getOrderLiftDetailFun();
    }
  }

  // 故障点回显时候用
  setFaultPoint = (list) => {
    list.forEach((item) => {
      this.getChooseFaultPoint(item);
    })
  }

  getChooseFaultPoint = (item) => {
    if (this.pointChooseIdList.indexOf(item.id)) {
      this.pointName.push(item.name)
      this.pointList.push(item)
      this.setState({
        pointName: String(this.pointName)
      })
      return
    }
    if (item.childrenList && item.childrenList.length) {
      this.setFaultPoint(item.childrenList)
    }
  }





  submit = () => {
    if (!this.orderId) {
      Toast.info("暂无日志信息")
      return
    }
    this.props.history.push({ pathname: `/touristslayout/log/${this.orderId}` });
  }

  handleChange = (e) => {
    console.log(e)
  }



  // 备注信息
  handleRemarkChange = (event, type) => {
    this.setState({ [type]: event.target.value });
  }

  // 更换配件信息
  handleReplacementParts = (event) => {
    this.setState({ replacementParts: event.target.value });
  }

  // 选择现场图片
  scenePhotoChange = (files, type, index) => {
    this.setState({
      scenePhoto: files
    })
  }

  // 选择工单图片
  orderPhotoChange = (files, type, index) => {
    this.setState({
      orderPhoto: files
    })
  }

  // 点击故障点打开弹窗
  handleClickfaultPointId = () => {
    if (this.state.status === 4) {
      return
    }
    this.setState({
      openPoint: true
    })
  }

  // 关闭故障点弹窗
  handleClosePoint = () => {
    this.setState({
      openPoint: false
    })
  }
  // 关签名弹窗
  handleCloseSignature = () => {
    this.setState({
      openSignature: false
    })
  }

  // 弹出签名弹窗
  clickSubmit = async () => {
    this.setState({
      openSignature: true
    })

  }

  // 点击节点
  handlePointClink = (val) => {
    if (val.childrenList) {
      return
    }
    const haveKey = this.pointChooseIdList.indexOf(val.id);
    if (haveKey >= 0) {
      this.pointChooseIdList.splice(haveKey, 1);
      this.pointList.splice(haveKey, 1);
    } else {
      this.pointChooseIdList.push(val.id);
      this.pointList.push(val)
    }
    let pointName = []
    this.pointList.forEach((item) => {
      pointName.push(item.name)
    })
    this.setState({
      pointChooseIdList: this.pointChooseIdList,
      pointName: String(pointName)
    })
  }

  // 获取签名的base64 然后提交服务器
  getSignature = async (base64) => {
    this.setState({
      animating: true
    })
    const res1 = await this.submitBase64List()
    if (res1) {
      const res = await uploadFileByBase64(base64)
      if (res.code === 200) {
        this.submitStatus = 4
        this.postData.signatureImageDataList = [{ imageUrl: res.data.url }]
        this.getLocation()
      }
    }
  }

  // 签完字确定后保存数据
  save = async () => {
    this.postData.status = this.submitStatus
    this.postData.remark = this.state.remark
    this.postData.replacementParts = this.state.replacementParts
    this.postData.faultDescription = this.state.faultDescription
    this.postData.solution = this.state.solution
    this.postData.faultPointIdList = this.pointChooseIdList
    this.postData.faultReasonId = this.state.value ? this.state.value[0] : ''
    this.postData.liftId = this.state.liftDetail.liftId
    this.postData.templateId = this.state.liftDetail.templateId
    const res = await saveOrder(this.postData)
    if (res.code === 200) {
      this.setState({
        animating: false,
        open: false
      }, () => {
        this.props.history.goBack()
      })
    } else {
      this.setState({
        animating: false,
        open: false
      })
      Toast.fail("提交失败")
    }
  }

  // 获取授权
  getWechatConfigFun = async () => {
    let url = window.location.href
    if (this.isIos()) {
      url = localStorage.getItem('url')
    }
    const res = await getWechatConfig(url)
    if (res.code === 200) {
      const data = { ...res.data }
      data.jsApiList = ['getLocation']
      wxValidationPermissions(data)
    }
  }

  // 获取地理位置
  getLocation = () => {
    const that = this
    wx.getLocation({
      type: 'wgs84',
      success: (res) => {
        const BMap = window.BMap
        const ggPoint = new BMap.Point(res.longitude, res.latitude)
        var convertor = new BMap.Convertor();
        var pointArr = [];
        pointArr.push(ggPoint);
        const geoc = new BMap.Geocoder()
        convertor.translate(pointArr, 1, 5, (res) => {
          if (res.status === 0) {
            that.postData.lon = res.points[0].lng
            that.postData.lat = res.points[0].lat
            geoc.getLocation(res.points[0], function (rs) {
              that.setState({
                address: rs.address
              })
              that.postData.address = rs.address
              that.save()
            })
          } else {
            that.save()
          }
        })
      },
      error: (error) => {
        that.save()
      }

    })
  }


  // 提交base64图片到服务器
  submitBase64List = async () => {
    this.postData = { ...this.state.detail }
    let orderImageDataList = []
    let liveImageDataList = []
    let orderImageBase64ListFinish = []
    let liveImageBase64ListFinish = []
    if (this.state.orderPhoto && this.state.orderPhoto.length) {
      const orderImageBase64List = []

      for (let i = 0; i < this.state.orderPhoto.length; i++) {
        if (this.state.orderPhoto[i].file) {
          orderImageBase64List.push(await transformFile(this.state.orderPhoto[i]))
        } else {
          orderImageBase64ListFinish.push({
            imageType: 2,
            imageUrl: this.state.orderPhoto[i].url
          })
        }
      }


      // this.state.orderPhoto.forEach(item => {
      //   if (item.file) {
      //     orderImageBase64List.push(item.url)
      //   } else {
      //     orderImageBase64ListFinish.push({
      //       imageType: 2,
      //       imageUrl: item.url
      //     })
      //   }
      // })
      const orderImageDataListRes = await batchUploadFileByBase64({ base64List: orderImageBase64List })
      if (orderImageDataListRes.code === 200) {
        orderImageDataList = orderImageDataListRes.data.map(item => {
          const obj = {
            imageType: 2,
            imageUrl: item.url
          }
          return obj
        })
      }
    }
    if (this.state.scenePhoto && this.state.scenePhoto.length) {
      const liveImageBase64List = []

      for (let i = 0; i < this.state.scenePhoto.length; i++) {
        if (this.state.scenePhoto[i].file) {
          liveImageBase64List.push(await transformFile(this.state.scenePhoto[i]))
        } else {
          liveImageBase64ListFinish.push({
            imageType: 2,
            imageUrl: this.state.scenePhoto[i].url
          })
        }
      }

      // this.state.scenePhoto.forEach(item => {
      //   if (item.file) {
      //     liveImageBase64List.push(item.url)
      //   } else {
      //     liveImageBase64ListFinish.push({
      //       imageType: 1,
      //       imageUrl: item.url
      //     })
      //   }
      // })
      const liveImageDataListRes = await batchUploadFileByBase64({ base64List: liveImageBase64List })
      if (liveImageDataListRes.code === 200) {
        liveImageDataList = liveImageDataListRes.data.map(item => {
          const obj = {
            imageType: 1,
            imageUrl: item.url
          }
          return obj
        })
      }
    }
    this.postData.orderImageDataList = orderImageBase64ListFinish.concat(orderImageDataList)
    this.postData.liveImageDataList = liveImageBase64ListFinish.concat(liveImageDataList)
    return true
  }


  // 中途离开
  liveSubmit = async () => {
    this.setState({
      animating: true
    })
    this.submitStatus = 2
    if (await this.submitBase64List()) {
      this.getLocation()
    }
  }







  render() {
    const { pointName, currentUserName, faultPointResultDTOList, scenePhoto, faultReason, signatureImage, orderPhoto, faultReasonInfoSimpleList, pointList, pointChooseIdList, liftDetail } = this.state
    const status = 4
    const map = pointList.map((item, index) => <Ff item={item} key={index} pointChooseIdList={pointChooseIdList} handlePointClink={this.handlePointClink} />)

    return (
      <div className="elevatorMaintenance">
        <div className="top">
          <ul>
            <li>
              项目名称<span>{liftDetail.projectName}</span>
            </li>
            <li>
              使用单位<span>{liftDetail.propertyUnitName}</span>
            </li>
            <li>
              使用证号<span>{liftDetail.useCertificateNumber}</span>
            </li>
            <li>
              电梯位置<span>{liftDetail.liftLocation}</span>
            </li>

          </ul>
        </div>
        <h1>维修记录</h1>
        <div className="information">
          <div className="information-item information-item-point">
            <span>维修人员</span>
            <span>{currentUserName}</span>
          </div>
          <div className="information-item information-item-point">
            <span>故障点</span>
            <span className="faultPoint">{faultReason}</span>
          </div>
          <div className="information-item information-item-point">
            <span>故障原因</span>
            <PointResult faultPointResultDTOList={faultPointResultDTOList} />
          </div>
          {/* <div className="pickerBox">
            <Picker data={faultReasonInfoSimpleList} cols={1} disabled={status === 4 ? true : false} className="forss" value={this.state.value} onChange={v => this.setState({ value: v })}>
              <List.Item arrow="horizontal">故障原因</List.Item>
            </Picker>
          </div> */}
          <div className="textAreaBox">
            <h6>故障描述</h6>
            <textarea placeholder="无" disabled={status === 4 ? true : false} value={this.state.faultDescription} onChange={(e) => { this.handleRemarkChange(e, 'faultDescription') }} />
          </div>
          <div className="textAreaBox">
            <h6>解决方案</h6>
            <textarea placeholder="无" disabled={status === 4 ? true : false} value={this.state.solution} onChange={(e) => { this.handleRemarkChange(e, 'solution') }} />
          </div>
          <div className="textAreaBox">
            <h6>备注信息</h6>
            <textarea placeholder="无" disabled={status === 4 ? true : false} value={this.state.remark} onChange={(e) => { this.handleRemarkChange(e, 'remark') }} />
          </div>
          <div className="textAreaBox">
            <h6>更换配件信息</h6>
            <textarea placeholder="无" disabled={status === 4 ? true : false} value={this.state.replacementParts} onChange={(e) => { this.handleRemarkChange(e, 'replacementParts') }} />
          </div>
          {
            status === 4 && !scenePhoto.length ? "" : (
              <div className="fileUploadBox">
                <h6>现场照片</h6>
                <ImagePicker
                  className={status === 4 ? "displayClose" : ""}
                  files={scenePhoto}
                  onChange={this.scenePhotoChange}
                  selectable={scenePhoto.length < 5 && status !== 4}
                  multiple={true}
                  accept="image/gif,image/jpeg,image/jpg,image/png"
                />
              </div>
            )
          }
          {
            status === 4 && !orderPhoto.length ? "" : (
              <div className="fileUploadBox">
                <h6>工单照片</h6>
                <ImagePicker
                  className={status === 4 ? "displayClose" : ""}
                  files={orderPhoto}
                  onChange={this.orderPhotoChange}
                  selectable={orderPhoto.length < 5 && status !== 4}
                  multiple={true}
                  accept="image/gif,image/jpeg,image/jpg,image/png"
                />
              </div>)
          }
          {
            signatureImage ?
              (<div className="fileUploadBox">
                <h6>处理人</h6>
                <img className="signatureImage" src={signatureImage} alt="" />
              </div>) : ''
          }
        </div>
        <Drawer direction="bottom" handleClose={this.handleClosePoint} open={this.state.openPoint}>
          <div className="faultPointIdBox">
            {map}
          </div>
        </Drawer>
        {
          status === 4 ? '' : (
            <div>
              <div className="footer" >
                <div onClick={this.liveSubmit}>中途离开</div>
                <div onClick={this.clickSubmit}>提交</div>
              </div>
              <Drawer direction="bottom" handleClose={this.handleCloseSignature} open={this.state.openSignature}>
                <Signature getSignature={this.getSignature} />
              </Drawer>
              <Lodding animating={this.state.animating} />
            </div>
          )
        }

      </div>
    )
  }
}

export default connect()(ElevatorMaintenance);