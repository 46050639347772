
import { fetch } from '../../plugin/axios'
const serverName = '/maintain'


export function getDocumentListTwoList(data) {
  return fetch({
    url: serverName + '/TechnicalDocument/pageList',
    method: 'POST',
    data
  })
}



