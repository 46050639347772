import React from 'react'
import commonAction from '@/actions/common'
import { getEmergencyRescue } from '@/api/TryOutPage/EmergencyRescue'
import { connect } from 'react-redux';
import './EmergencyRescue.scss'
class EmergencyRescue extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      detail: {}
    }
  }
  componentWillMount() {
    this.props.dispatch({ type: commonAction.setPathname, pathname: this.props.path })
    this.props.onRef(this);
    this.getEmergencyRescueFun(this.props.match.params.id * 1)
  }

  getEmergencyRescueFun = async (liftId) => {
    const res = await getEmergencyRescue({ liftId })
    if (res.code === 200) {
      this.setState({
        detail: res.data
      })
    }
  }

  submit = () => {

  }
  render() {
    const { detail } = this.state
    return (
      <div className="emergencyRescue">
        <div className="emergencyRescueItem">
          <div>
            <p><span>当前电梯：</span><span>{detail.liftLocation}</span></p>
            <p><span>使用单位：</span><span>{detail.propertyUnitName}</span></p>
            <p className="emergencyRescuePhone"><span>联系电话：</span><a href={`tel:${detail.propertyConsumerHotLine}`}>{detail.propertyConsumerHotLine}</a></p>
          </div>
          <div>
            <p><span>维保公司：</span><span>{detail.maintainUnitName}</span></p>
            <p className="emergencyRescuePhone"><span>联系电话：</span><a href={`tel:${detail.maintainConsumerHotLine}`}>{detail.maintainConsumerHotLine}</a></p>
            <p><span>维保人员：</span><span>{detail.maintainUserPhone}</span></p>
          </div>
        </div>
        <div className="emergencyRescuePrompt">
          <h5>温馨提示：</h5>
          <p>1、电梯按钮的报警铃是对外救援的方法。</p>
          <p>2、也可拨打上述电话，联系电梯管理方。</p>
        </div>
      </div>
    )
  }
}


export default connect()(EmergencyRescue);