import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import ListItem from './ListItem'


class MainSwiper extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      list: ["六点", "七点"],
    }
    this.currentIndex = 0
    this.swiper = null
  }
  onSwiper = (swiper) => {
    this.swiper = swiper
  }
  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this)
    }
    this.list = [...this.props.list]
  }

  changeSlide = (index) => {
    if (this.swiper) {
      this.swiper.slideTo(index)
    }
  }

  touchEnd = (swiper) => {
    console.log(swiper.realIndex)
    if (swiper.realIndex === this.currentIndex) {
      return
    }
    this.currentIndex = swiper.realIndex
    if (this.props.touchIndex || this.props.touchIndex===0) {
      this.props.touchIndex(swiper.realIndex)
    }
  }

  // 设置新值
  setNewData = (index, val) => {
    if (this.props.status === 4) {
      return
    }
    this.list[this.currentIndex].itemDTOList.some((item) => {
      if (item.itemId === val.itemId) {
        item.result = index
      }
    })

  }
  

  getNewData = () => {
    return this.list
  }

  // 设置一个变量存储放进来的值 自组建调用这里面的方法进行更改



  render() {
    const list = this.props.list
    this.list = [...this.props.list]
    let ele = <div></div>
    if (list && list.length > 0) {
      ele = (<Swiper
        spaceBetween={0}
        slidesPerView={1}
        slideToClickedSlide={true}
        watchSlidesVisibility={true}
        watchSlidesProgress={true}
        onSwiper={(swiper) => this.onSwiper(swiper)}
        centeredSlides={true}
        centeredSlidesBounds={false}
        // onSlideChangeTransitionStart={this.touchEnd}
        onTransitionEnd={this.touchEnd}
        initialSlide={0}
      >
        {
          list.map((item, index) => {
            return (
              <SwiperSlide key={index + 1}>
                {item.itemDTOList.map(it => <ListItem status={this.props.status} setNewData={this.setNewData} key={it.itemId + 1} item={it} />)}
              </SwiperSlide>
            )
          })
        }
      </Swiper>)
    }
    return (
      <div>
        {ele}
      </div>
    )
  }
}

export default MainSwiper;