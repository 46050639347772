import { fetch } from '@/plugin/axiosNoLogin'
const serverName = '/repair'

/**
 * 获取工单日志
 * @param {*} params 
 */
export function getOrderLogInfo(params) {
  return fetch({
    url: serverName + '/api/repair/order/logInfo',
		method: 'GET',
		params
  })
}