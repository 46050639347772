import React from 'react'
import './MyLiftEdit.scss'
import commonAction from '../../actions/common'
import { connect } from 'react-redux';
import { getLiftDetail, getProjectSimpleList, errorCorrection, getTemplateSimpleList, getPropertyUnitSimpleList } from '../../api/MyLiftEdit'
import { getDictionaryList } from '../../api/Sys'
import Select from '../../components/Select'
import { InputItem, Toast, DatePicker, List } from 'antd-mobile'


const errorMessage =
{
  registerCode: '注册代码不能为空',
  liftLocation: '电梯位置不能为空',
  equipmentCode: '设备编号不能为空',
  liftBrand: '电梯品牌不能为空'
}

class MyLiftEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      detail: {
        projectName: '',
        projectId: 11,
        registerCode: '',
        liftLocation: '',
        equipmentCode: '',
        liftBrand: '',
        manufactureCompany: '',
        inspectionProject: '',
        propertyProjectName: '',
        inspectionUnit: ''

      },
      projectList: [],
      project: null,
      hasError: {
        registerCode: false,
        liftLocation: false,
        equipmentCode: false,
        liftBrand: false
      },
      dtlxList: [],
      dateObj: {
        manufactureDate: '',
        annualInspectionDate: ''
      },
      liftTypeObj: {
        dictionaryValue: '',
        dictionaryCode: ''
      },
      templateList: [],
      maintenanceOrderObj: {
        templateName: '',
        id: ''
      },
      propertyUnitList: [],
      propertyUnit: {
        id: '',
        unitName: ''
      }

    }
    this.getProjectSimpleListParams = {
      keyword: "",
      size: 20
    }
    this.getPropertyUnitSimpleListParams = {
      keyword: "",
      size: 20
    }
    this.canSearch = true
  }
  componentWillMount() {
    this.props.dispatch({ type: commonAction.setPathname, pathname: this.props.path })
    this.props.onRef(this);
    this.getLiftDetailFun(this.props.match.params.id * 1)
    this.getProjectSimpleListFun()
    this.getDictionaryListFun()
    this.getTemplateSimpleListFun()
    this.getPropertyUnitSimpleListFun()
  }
  // 获取电梯详情
  getLiftDetailFun = async (liftId) => {
    const res = await getLiftDetail({ liftId });
    if (res.code === 200) {
      let dateObj = {
        manufactureDate: new Date(res.data.manufactureDate),
        annualInspectionDate: new Date(res.data.annualInspectionDate)
      }
      const project = {
        id: res.data.projectId,
        projectName: res.data.projectName
      }
      const liftTypeObj = {
        dictionaryValue: res.data.liftTypeName,
        dictionaryCode: res.data.liftTypeCode
      }
      const maintenanceOrderObj = {
        id: res.data.maintenanceOrderId,
        templateName: res.data.maintenanceOrderName
      }
      const propertyUnit = {
        id: res.data.propertyUnitId,
        unitName: res.data.propertyUnitName
      }
      this.setState({
        detail: res.data,
        dateObj,
        project,
        liftTypeObj,
        maintenanceOrderObj,
        propertyUnit
      })
    }
  }
  // 获取单位列表
  getProjectSimpleListFun = async () => {
    this.canSearch = false
    const res = await getProjectSimpleList(this.getProjectSimpleListParams)
    if (res.code === 200) {
      this.setState({
        projectList: res.data
      })
    }
    this.canSearch = true
  }

  // 获取使用单位列表
  getPropertyUnitSimpleListFun = async () => {
    this.canSearch = false
    const res = await getPropertyUnitSimpleList(this.getPropertyUnitSimpleListParams)
    if (res.code === 200) {
      this.setState({
        propertyUnitList: res.data
      })
    }
    this.canSearch = true
  }

  // 获取电梯类型
  getDictionaryListFun = async () => {
    const res = await getDictionaryList({ dictionaryClass: 'DTLX' })
    if (res.code === 200) {
      this.setState({
        dtlxList: res.data
      })
    }
  }

  // 获取保养单

  getTemplateSimpleListFun = async (templateName) => {
    const res = await getTemplateSimpleList({ templateName })
    if (res.code === 200) {
      this.setState({
        templateList: res.data
      })
    }
  }
  // 使用单位聚焦
  handleFocusPropertyUnit = () => {
    this.getPropertyUnitSimpleListParams.keyword = ""
    this.getPropertyUnitSimpleListFun()
  }
  // 搜索使用单位
  handleSearchPropertyUnit = (val) => {
    if (this.canSearch) {
      this.getPropertyUnitSimpleListParams.keyword = val
      this.getPropertyUnitSimpleListFun()
    }
  }
  // 选择使用单位
  handleChoosePropertyUnit = (val) => {
    this.setState({
      propertyUnit: val
    })
  }
  // 搜索项目
  handleSearchProject = (val) => {
    if (this.canSearch) {
      this.getProjectSimpleListParams.keyword = val
      this.getProjectSimpleListFun()
    }
  }
  // 下拉框聚焦
  handleFocus = () => {
    this.getProjectSimpleListParams.keyword = ""
    this.getProjectSimpleListFun()
  }
  // 返回当前选中的值
  handleChoose = (val) => {
    this.setState({
      project: val
    })
  }
  // 保养单选中
  handleChooseMaintenanceOrder = (val) => {
    this.setState({
      maintenanceOrderObj: val
    })
  }
  // 搜索保养单
  handleSearchMaintenanceOrder = (val) => {
    this.getTemplateSimpleListFun(val)
  }
  // 保养单聚焦
  handleFocusMaintenanceOrder = () => {
    this.getTemplateSimpleListFun()
  }
  // 选择电梯类型
  handleChooseLiftType = (val) => {
    this.setState({
      liftTypeObj: val
    })
  }
  submit = async () => {
    let canSave = true
    const hasError = { ...this.state.hasError }
    let newHasError = { ...this.state.hasError }
    for (let key in hasError) {
      if (!this.state.detail[key]) {
        newHasError[key] = true
        canSave = false
      }
      this.setState({
        hasError: newHasError
      })
    }
    if (!canSave) {
      return
    }
    let postData = { ...this.state.detail }
    const dateObj = { ...this.state.dateObj }
    if (dateObj.manufactureDate) {
      postData.manufactureDate = this.initDate(dateObj.manufactureDate)
    }
    if (dateObj.annualInspectionDate) {
      postData.annualInspectionDate = this.initDate(dateObj.annualInspectionDate)
    }
    postData.projectId = this.state.project.id
    postData.projectName = this.state.project.projectName
    postData.liftTypeCode = this.state.liftTypeObj.dictionaryCode
    postData.liftTypeName = this.state.liftTypeObj.dictionaryValue
    postData.maintenanceOrderId = this.state.maintenanceOrderObj.id
    postData.maintenanceOrderName = this.state.maintenanceOrderObj.templateName
    postData.propertyUnitId = this.state.propertyUnit.id
    postData.propertyUnitName = this.state.propertyUnit.unitName
    const res = await errorCorrection(postData)
    if (res.code === 200) {
      Toast.success("保存成功")
      this.props.history.goBack()
      return
    }
    Toast.fail("保存失败")
  }
  initDate = (v) => {
    let needDate = new Date(v)
    let needYear = needDate.getFullYear()
    let needMonth = this.format(needDate.getMonth() + 1)
    let needDay = this.format(needDate.getDate())
    return `${needYear}-${needMonth}-${needDay}`
  }
  format = (num) => {
    let str = num >= 10 ? num : '0' + num
    return str
  }


  // 输入框修改
  handleChange = (e, attrName) => {

    let newDetail = { ...this.state.detail, [attrName]: e }
    this.setState({
      detail: newDetail,
    })
    let newHasError = {}
    if (this.state.hasError[attrName] !== false && this.state.hasError[attrName] !== true) {
      return
    }
    if (!e) {
      newHasError = { ...this.state.hasError, [attrName]: true }
    } else {
      newHasError = { ...this.state.hasError, [attrName]: false }
    }
    this.setState({
      hasError: newHasError
    })
  }

  // 日期修改
  handleChangeDate = (attrName) => {
    return (e) => {
      let newDateObj = { ...this.state.dateObj, [attrName]: e }
      this.setState({
        dateObj: newDateObj
      })
    }
  }

  selectChange = (attrName) => {
    return (e) => {
      this.setState({
        [attrName]: e
      })
    }
  }

  onErrorClick = (attrName) => {
    if (!this.state.hasError[attrName]) {
      return
    }
    Toast.info(errorMessage[attrName])

  }





  render() {
    const { detail, dateObj } = this.state
    return (
      <div className="commonBodyBox myLiftEditBox">
        <div>
          <h3>电梯信息</h3>
          <ul>
            <li>
              <span>所属项目</span>

              {/* <input type="text" value={detail.projectName} onChange={this.handleChange('projectName')} /> */}
              <div className="selectMain">
                <Select label="projectName" handleChoose={this.handleChoose} handleFocus={this.handleFocus} valueKey="id" chooseData={this.state.project} search={this.handleSearchProject} list={this.state.projectList} />
              </div>

            </li>
            <li>
              <InputItem
                type="text"
                placeholder="请输入注册代码"
                error={this.state.hasError['registerCode']}
                onErrorClick={(e) => this.onErrorClick('registerCode')}
                onChange={(e) => this.handleChange(e, 'registerCode')}
                value={detail.registerCode}
              >注册代码</InputItem>
            </li>
            <li>
              <InputItem
                type="text"
                placeholder="请输入电梯位置"
                error={this.state.hasError['liftLocation']}
                onErrorClick={(e) => this.onErrorClick('liftLocation')}
                onChange={(e) => this.handleChange(e, 'liftLocation')}
                value={detail.liftLocation}
              >电梯位置</InputItem>
            </li>
            <li>
              <InputItem
                type="text"
                placeholder="请输入设备编号"
                error={this.state.hasError['equipmentCode']}
                onErrorClick={(e) => this.onErrorClick('equipmentCode')}
                onChange={(e) => this.handleChange(e, 'equipmentCode')}
                value={detail.equipmentCode}
              >设备编号</InputItem>
            </li>
            <li>
              <span>保养单</span>
              <div className="selectMain">
                <Select label="templateName" handleChoose={this.handleChooseMaintenanceOrder} handleFocus={this.handleFocusMaintenanceOrder} valueKey="id" chooseData={this.state.maintenanceOrderObj} search={this.handleSearchMaintenanceOrder} list={this.state.templateList} />
              </div>

            </li>
            <li>
              <InputItem
                type="text"
                placeholder="请输入电梯品牌"
                error={this.state.hasError['liftBrand']}
                onErrorClick={(e) => this.onErrorClick('liftBrand')}
                onChange={(e) => this.handleChange(e, 'liftBrand')}
                value={detail.liftBrand}
              >电梯品牌</InputItem>
            </li>
            <li>
              <span>电梯类型</span>
              <div className="selectMain">
                <Select label="dictionaryValue" handleChoose={this.handleChooseLiftType} valueKey="dictionaryCode" chooseData={this.state.liftTypeObj} list={this.state.dtlxList} />
              </div>
            </li>
          </ul>
          <div className="coverBox"></div>
          <h3>日期信息</h3>
          <ul>
            <li>
              {/* <span>制造日期</span>
              <span>{detail.manufactureDate}</span> */}
              <div className="chooseTime">
                <DatePicker

                  mode="date"
                  title="制造日期"
                  extra="Optional"
                  value={dateObj.manufactureDate}
                  onChange={this.handleChangeDate("manufactureDate")}
                >
                  <List.Item arrow="horizontal">制造日期</List.Item>
                </DatePicker>
              </div>
            </li>
            <li>
              <div className="chooseTime">
                <DatePicker

                  mode="date"
                  title="检验日期"
                  extra="Optional"
                  value={dateObj.annualInspectionDate}
                  onChange={this.handleChangeDate("annualInspectionDate")}
                >
                  <List.Item arrow="horizontal">检验日期</List.Item>
                </DatePicker>
              </div>
            </li>
          </ul>
          <div className="coverBox"></div>
          <h3>单位信息</h3>
          <ul>
            <li>
              <InputItem
                type="text"
                placeholder="请输入制造单位"
                error={this.state.hasError['manufactureCompany']}
                onErrorClick={(e) => this.onErrorClick('manufactureCompany')}
                onChange={(e) => this.handleChange(e, 'manufactureCompany')}
                value={detail.manufactureCompany}
              >制造单位</InputItem>
            </li>
            <li>
              <InputItem
                type="text"
                placeholder="请输入检验单位"
                error={this.state.hasError['inspectionUnit']}
                onErrorClick={(e) => this.onErrorClick('inspectionUnit')}
                onChange={(e) => this.handleChange(e, 'inspectionUnit')}
                value={detail.inspectionUnit}
              >检验单位</InputItem>
            </li>
            <li>
              <span>使用单位</span>
              <div className="selectMain">
                <Select label="unitName" handleChoose={this.handleChoosePropertyUnit} handleFocus={this.handleFocusPropertyUnit} valueKey="id" chooseData={this.state.propertyUnit} search={this.handleSearchPropertyUnit} list={this.state.propertyUnitList} />
              </div>
            </li>
            <li>
              <span>单位联系人</span>
              <span>{detail.propertyProjectContact}</span>
            </li>
            <li>
              <span>联系人电话</span>
              <span>{detail.propertyProjectContactPhone}</span>
            </li>
          </ul>
        </div>
      </div>
    )
  }

}



export default connect()(MyLiftEdit);