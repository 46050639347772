import React from 'react'
import commonAction from '../../actions/common'
import { connect } from 'react-redux'
import { getLiftDetail, saveLocation } from '@/api/LiftLocation'
import { getWechatConfig, wxValidationPermissions } from '../../api/Sys'
import wx from 'weixin-js-sdk'
import './LiftLocation.scss'
import { Toast } from 'antd-mobile'
class LiftLocation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    }
    this.lat = 39.915
    this.lon = 116.404
    this.map = null
    this.BMap = window.BMap
    this.address = ''
  }
  componentWillMount() {
      this.getWechatConfigFun()
  }
  componentDidMount() {
    const pathname = this.props.path;
    this.props.dispatch({ type: commonAction.setPathname, pathname })
    this.props.onRef(this)
    this.getLiftDetailFun(this.props.match.params.id)
  }
  // 获取授权
  getWechatConfigFun = async () => {
    let url = window.location.href
    if (this.isIos()) {
      url = localStorage.getItem('url')
    }
    const res = await getWechatConfig(url)
    if (res.code === 200) {
      const data = { ...res.data }
      data.jsApiList = ['getLocation']
      wxValidationPermissions(data)
    }
  }

  // 获取电梯详情
  getLiftDetailFun = async (liftId) => {
    const res = await getLiftDetail({ liftId })
    if (res.code === 200) {
      this.lat = res.data.lat
      this.lon = res.data.lon
      this.address = res.data.liftLocation
    }
    if (!this.lat) {
      this.getLocation()
    }
    this.initMap()
  }

  // 判断是否是ios
  isIos = () => {
    const u = navigator.userAgent;
    return u.indexOf("iPhone") > -1 || u.indexOf("Mac OS") > -1;
  }

  // 初始化地图
  initMap = () => {
    
    this.map = new this.BMap.Map("allmap"); // 创建Map实例
    this.setCenter()
    // this.setPoint()
    
  }

  // 设置中心点
  setCenter = () => {
    this.map.centerAndZoom(new this.BMap.Point(this.lon, this.lat), 15); // 初始化地图,设置中心点坐标和地图级别
  }

  //创建point
  setPoint = () => {
    this.map.clearOverlays()
    this.map.addOverlay(new this.BMap.Marker(new this.BMap.Point(this.lon, this.lat))) 
  } 

  // 获取地理位置
  getLocation = () => {
    const that = this
    wx.getLocation({
      type: 'wgs84',
      success: (res) => {
        const BMap = window.BMap
        const ggPoint = new BMap.Point(res.longitude, res.latitude)
        var convertor = new BMap.Convertor();
        var pointArr = [];
        pointArr.push(ggPoint);
        const geoc = new BMap.Geocoder()
        convertor.translate(pointArr, 1, 5, (res) => {
          if (res.status === 0) {
            that.lon = res.points[0].lng
            that.lat = res.points[0].lat
            geoc.getLocation(res.points[0], function (rs) {
              that.setState({
                address: rs.address
              })
              that.address = rs.address
              
            })
            this.setCenter()
            this.setPoint()
          }
        })
      }

    })
  }

  // 提交
  submit = () => {

  }

  // 重置
  reset = () => {
    this.map.clearOverlays()
    this.lon = ''
    this.lat = ''
    this.address = ''
    this.save('reset')
  }

  // 定位
  location = () => {
    this.getLocation()
  }

  // 保存
  save = async (val) => {
    const data = {
      lat: this.lat,
      lon: this.lon,
      liftPosition: this.address,
      liftId: this.props.match.params.id
    }
    const res = await saveLocation(data)
    if (res.code === 200) {
      if (val !== 'reset') {
        Toast.success("保存成功")
      } else {
        Toast.success("重置成功")
      }
    }
  }
  render() {
    return (
      <div className="liftLocationBox commonBodyBox">
        <div id="allmap" style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }}></div>
        <div className="liftLocationBoxFoot">
          <span onClick={this.reset}>重置</span>
          <span onClick={this.location}>定位</span>
          <span onClick={this.save}>保存</span>
        </div>
      </div>
    )
  }
}

export default connect()(LiftLocation);