import React from 'react';
import AnimationHeader from '../../components/AnimationHeader'
import { InputItem, Button, Toast } from 'antd-mobile';
import { createForm } from 'rc-form';
import './Login.scss'
import { authLogin } from '../../api/Sys/index'
import { base64encode } from "../../libs/base64"
import cookies from '../../libs/util.cookies'
import { getWechatConfig, wxValidationPermissions } from '../../api/Sys'


class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isSubmit: false,
      password: '',
      username: ''
    }
  }
  onSubmit = async() => {
    
    Toast.loading("登陆中...", 0)
	
	let state = this.props.location.state;
	let wxAppNickname =  '';
	let openId = '';
	if (state) {
		wxAppNickname = state.wxAppNickname || '';
		openId = state.openId || '';
	}
	
    const res = await authLogin({
      username: this.state.username,
      password: base64encode(this.state.password),
      wxAppNickname: wxAppNickname,
      openId: openId
    })
    Toast.hide()
    if (res.code === 200) {
 
      localStorage.setItem('username', this.state.username)
      localStorage.setItem('password', this.state.password)
      cookies.set('token', res.data.token, { expires: 15 })
      this.props.history.replace('/home')
    } else {
      Toast.fail(res.message, 3)
    }
    
  }
  usernameChange = (username)=> {
    this.setState({
      username
    })
  }
  passwordChange = (password) => {
    this.setState({
      password
    })
  }

  componentDidMount() {
    if (localStorage.getItem('username')) {
      this.usernameChange(localStorage.getItem('username'))
    }
    if (localStorage.getItem('password')) {
      this.passwordChange(localStorage.getItem('password'))
    }
    let url = window.location.href.split("#")[0];
    localStorage.setItem('url', url)
  }
  
  // checkSubmit = (val) => {
  //   console.log(val)
  //   console.log(this.props.form.getFieldProps("username").value)
  //   console.log(this.props.form.getFieldProps("password").value)
  // }
  render() {
    return (
      <div className="loginBox">
        <AnimationHeader title=""/>
        <div className="inputBox">
       
          <InputItem
            onChange={this.usernameChange}
            value={this.state.username}
            type="text"
            placeholder="账号"
          >账号</InputItem>
          <br />
          <InputItem
            type="password"
            placeholder="****"
            onChange={this.passwordChange}
            value={this.state.password}
          >密码</InputItem>
          <Button className="button" disabled={!this.state.username || !this.state.password} onClick={this.onSubmit} type="primary">登陆</Button>
        </div>
      </div>
    )
  }
}


export default createForm()(Login);