import { fetch } from '../../plugin/axios'
const serverName = '/maintain'

export function getLiftDetail(params) {
  return fetch({
    url: serverName + '/app/lift/liftDetail',
		method: 'GET',
		params
  })
}


export function saveLocation(data) {
  return fetch({
    url: serverName + '/app/lift/location',
		method: 'POST',
		data
  })
}