import { fetch } from '../../plugin/axios'

/**
 * 获取故障原因
 * @param {*} params 
 */
export function getFaultReasonInfoSimpleList(params) {
  return fetch({
    url: '/repair/repair/faultReason/simpleList',
		method: 'GET',
		params
  })
}


/**
 * 获取故障点
 * @param {*}  
 */
export function getPointTree(data) {
  return fetch({
    url: '/repair/repair/point/tree',
    method: 'POST',
    data
  })
}


/**
 * 获取工单中电梯信息
 * @param {*} params 
 */
export function getOrderLiftDetail(params) {
  return fetch({
    url: '/repair/app/repair/order/orderLiftDetail',
		method: 'GET',
		params
  })
}


/**
 * 获取故障点
 * @param {*}  
 */
export function saveOrder(data) {
  return fetch({
    url: '/repair/app/repair/order/saveOrder',
    method: 'POST',
    data
  })
}


/**
 * 获取工单详情
 * @param {*}  
 */
export function getOrderDetail(params) {
  return fetch({
    url: '/repair/app/repair/order/orderDetail',
    method: 'GET',
    params
  })
}



/**
 * 获取材料库
 * @param {*}  
 */
export function getSimpleList(data) {
  return fetch({
    url: '/repair/materialInfo/simpleList',
    method: 'POST',
    data
  })
}


/**
 * 用户下啦接口
 * @param {*}  
 */
export function getUserSimpleList(data) {
  return fetch({
    url: '/auth/subPlatform/user/simpleList',
    method: 'POST',
    data
  })
}
