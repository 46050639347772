/**
 * 手动写的弹窗 可以支持左右下 不支持上 根据direction值来判断
 */

import React from 'react'
import './Drawer.scss'

class Drawer extends React.Component {
  constructor(props) {
    super(props);
    this.drawerBody = React.createRef();
    this.state = {
      style: {
        
      },
      drawBodyClassName: ''

    }
    this.direction = "left"
  }
  componentDidMount() {
    if (this.props.direction) {
      this.direction = this.props.direction
    }
    let style = {
      left: '-62%'
    }
    if (this.direction === "left") {
      style = {
        left: '-62%',
        transition: "left .3s ease-in-out"
      }
    } else if (this.direction === "right") {
      style = {
        right: '-62%',
        transition: "right .3s ease-in-out"
      }
    } else if (this.direction === "bottom") {
      style = {
        top: '102%',
        transition: "top .3s ease-in-out",
        width: "100%"
      }
    }
    this.setState({
      style
    })
  }
  handleClose = (e) => {
    if (e.target === this.drawerBody.current && this.props.handleClose) {
      this.props.handleClose()
    }
  }
  componentWillReceiveProps() {
    this.getDrawBodyClassName()
  }
  getDrawBodyClassName() {
    this.timmer = setTimeout(() => {
      if (!this.props.open) {
        this.setState({
          drawBodyClassName: 'drawerMain'
        })
        return
      }
      if (this.direction === "left" && this.props.open) {
        this.setState({
          drawBodyClassName: "drawerMain drawerMainLeftIn"
        })
        return
      }
      if (this.direction === "right" && this.props.open) {
        this.setState({
          drawBodyClassName: "drawerMain drawerMainRightIn"
        })
        return
      }
      if (this.direction === "bottom" && this.props.open) {
        this.setState({
          drawBodyClassName: "drawerMain drawerMainBottomIn"
        })
        return
      }
    }, 30);
  }

  componentWillUnmount() {
    if (this.timmer) {
      clearTimeout(this.props.timmer)
    }
  }

  render() {
    const ele = this.props.open ? <div className="drawerBox">
      <div className="drawerBody" ref={this.drawerBody} onClick={(e) => this.handleClose(e)}>
        <div className={this.state.drawBodyClassName} style={this.state.style}>
          <div className="propsChildrenBox">
            {this.props.children}
          </div>
        </div>
      </div>
    </div> : '';
    return ele
  }
}

export default Drawer;