import { fetch } from '@/plugin/axiosNoLogin'
const serverName = '/maintain'

export function getLiftDetail(params) {
  return fetch({
    url: serverName + '/api/liftDetail',
		method: 'GET',
		params
  })
}


export function bindLift(params) {
  return fetch({
    url: '/auth/customer/bindLift',
		method: 'GET',
		params
  })
}