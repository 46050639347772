import React from 'react'
import './Employees.scss'
import closeImg from '../../static/images/whiteClose.png'
import threePoint from '../../static/images/threePoint.png'
import { getLiftDetail } from '@/api/MyLiftDetail'
import continueRepair from '../../static/images/continueRepair.png'
import continueMaintain from '../../static/images/continueMaintain.png'
import elevatorInspect from '../../static/images/elevatorInspect.png'
import liftInformation from '../../static/images/liftInformation.png'
import Lodding from '../../components/Lodding'
import { getQueryVariable } from '../../libs/utils'
import elevatorPosition from '../../static/images/elevatorPosition.png'
import { getWechatConfig, wxValidationPermissions } from '../../api/Sys'
import wx from 'weixin-js-sdk'
import { Toast } from 'antd-mobile'

class Employees extends React.Component {
  constructor(props) {
    super(props)
    this.dateString = ''
    this.initDate()
    this.state = {
      dayStr: '',
      detail: {
        projectName: '',
        liftLocation: ''
      },
      list: [
        {
          icon: liftInformation,
          name: 'liftInformation',
          text: '电梯信息',
          url: '/employeeslayout/myLiftDetail/',
          show: true
        },
        {
          icon: continueMaintain,
          name: 'continueMaintain',
          text: '维保工单',
          url: '/layout/workOrderDetails',
          show: true
        },
        {
          icon: continueRepair,
          name: 'continueRepair',
          text: '开始维修',
          url: '/employeeslayout/elevatorMaintenance',
          show: true
        },
        {
          icon: elevatorPosition,
          text: '电梯定位',
          show: true,
          url: '/employeeslayout/liftLocation/',
          name: 'elevatorPosition',
        },
        {
          icon: elevatorInspect,
          name: 'elevatorInspect',
          // text: '电梯巡查'
          text: '导航',
          url: '/touristslayout/emergencyRescue/',
          show: true
        },
        {
          icon: liftInformation,
          name: 'ercode',
          text: '二维码',
          url: '/employeeslayout/ercode/',
          show: true
        },
        {
          icon: liftInformation,
          name: 'document',
          text: '技术文档',
          url: '/employeeslayout/document/',
          show: true
        },
        {
          icon: continueRepair,
          name: 'annualInspectionDue',
          text: '年检到期',
          url: '/employeeslayout/annualInspectionDue',
          show: false
        },
        {
          icon: continueRepair,
          name: 'annualInspectionAndOverhaul',
          text: '年检大修',
          url: '/employeeslayout/annualInspectionAndOverhaul',
          show: false
        },
      ],
      animating: false,
      url: ''
    }
    this.liftId = null
    this.maintainOrderId = ''
    this.liftCode = ''
    this.postData = {
      lon: '',
      lat: '',
      address: ''
    }
  }
  initDate = () => {
    let needDate = new Date()
    this.needDate = needDate
    let needYear = needDate.getFullYear()
    let needMonth = this.format(needDate.getMonth() + 1)
    let needDay = this.format(needDate.getDate())
    this.dateString = `${needYear}${needMonth}${needDay}`

  }
  componentWillMount() {
    this.getWechatConfigFun()
    let liftCode = ''
    if (this.props.match.params.liftCode) {
      liftCode = this.props.match.params.liftCode
    } else {
      liftCode = getQueryVariable('liftCode')
    }
    this.liftCode = liftCode
    if (liftCode) {
      this.getLiftDetailFun(liftCode)
    }
  }

  // 判断是不是ios
  isIos = () => {
    const u = navigator.userAgent;
    return u.indexOf("iPhone") > -1 || u.indexOf("Mac OS") > -1;
  }

  // 获取授权
  getWechatConfigFun = async () => {
    let url = window.location.href.split("#")[0];
    if (this.isIos()) {
      url = localStorage.getItem('url')
    }
    const res = await getWechatConfig(url)
    if (res.code === 200) {
      const data = { ...res.data }
      data.jsApiList = ['getLocation']
      wxValidationPermissions(data)
    }
  }

  // 获取地理位置
  getLocation = () => {
    const that = this
    wx.getLocation({
      type: 'wgs84',
      success: (res) => {
        const BMap = window.BMap
        const ggPoint = new BMap.Point(res.longitude, res.latitude)
        var convertor = new BMap.Convertor();
        var pointArr = [];
        pointArr.push(ggPoint);
        const geoc = new BMap.Geocoder()
        convertor.translate(pointArr, 1, 5, (res) => {
          if (res.status === 0) {
            that.postData.lon = res.points[0].lng
            that.postData.lat = res.points[0].lat
            geoc.getLocation(res.points[0], function (rs) {
              that.setState({
                address: rs.address
              })
              that.postData.address = rs.address
              // that.save()
            })
          } else {
            // that.save()
          }
        })
      },
      error: (error) => {
        // that.save()
      }

    })
  }


  getLiftDetailFun = async (liftCode) => {
    const res = await getLiftDetail({ liftCode });
    this.setState({
      animating: true
    })
    if (res.code === 200 && res.data) {

      const list = [...this.state.list];
      const newList = [...list]
      list.some((item, index) => {
        if (item.name === 'continueRepair') {
          if (res.data.repairOrderId) {
            item.text = "继续维修"
          } else {
            item.text = "开始维修"
          }
          newList[index] = item
          return true
        }
      })
      if (res.data.maintainOrderId && res.data.maintainOrderEntranceStatus !== -1) {
        list.some((item, index) => {
          if (item.name === 'continueMaintain') {
            if (res.data.maintainOrderEntranceStatus === 2) {
              item.text = "继续维保"
            } else {
              item.text = "开始维保"
            }
            newList[index] = item
            return true
          }
        })
      } else {
        list.some((item, index) => {
          if (item.name === 'continueMaintain') {
            item.show = false
            newList[index] = item
            return true
          }
        })
      }
      console.log(res.data)
      if (res.data.annualInspectionEntranceStatus > 0) {
        list.some((item, index) => {
          if (item.name === 'annualInspectionDue') {
            item.show = true
            newList[index] = item
            return true
          }
        })
      }
      if (res.data.annualInspectionRepairOrderId) {
        list.some((item, index) => {
          if (item.name === 'annualInspectionAndOverhaul') {
            item.show = true
            newList[index] = item
            return true
          }
        })
      }
      this.setState({
        detail: res.data,
        list: newList,
        animating: false
      }, () => {
        const that = this;
        wx.ready(() => {
          that.getLocation();
        })
      })

      this.liftId = res.data.liftId
      window.liftId = res.data.liftId
      this.repairOrderId = res.data.repairOrderId
      this.maintainOrderId = res.data.maintainOrderId
    }
  }



  componentDidMount() {
    this.setState({
      dayStr: this.formatDay(this.needDate.getDay())
    })
  }
  formatDay = (num) => {
    let str = ''
    switch (num) {
      case 0:
        str = "日"
        break
      case 1:
        str = "一"
        break
      case 2:
        str = "二"
        break
      case 3:
        str = "三"
        break
      case 4:
        str = "四"
        break
      case 5:
        str = "五"
        break
      case 6:
        str = "六"
        break
      default:
        str = ''
    }
    return str
  }
  format = (num) => {
    let str = num >= 10 ? num : '0' + num
    return str
  }

  jump = (item) => {
    console.log(item)
    if (item.name === "continueMaintain") {
      this.props.history.push({ pathname: `/layout/workOrderDetails/${this.maintainOrderId}` });
      return
    }
    if (item.name === "ercode") {
      let url = item.url + this.liftCode
      this.props.history.push({ pathname: url });
      return
    }
    if (item.name === "continueRepair") {
      let url = item.url + '/' + this.liftCode
      this.props.history.push({ pathname: url });
      return
    }
    if (item.name === "annualInspectionDue") {
      let url = item.url + '/' + this.liftCode
      this.props.history.push({ pathname: url });
      return
    }
    if (item.name === "annualInspectionAndOverhaul") {
      let url = item.url + '/' + this.liftCode
      this.props.history.push({ pathname: url });
      return
    }
    if (item.name === "elevatorInspect") {
      if (!this.state.detail.lat || !this.state.detail.lon) {
        Toast.fail("电梯经纬度为空,请联系管理员补充");
        return
      }
      console.log(this.postData)
      if (this.postData.lon && this.postData.lat) {

        const detail = this.state.detail
        let url = `http://api.map.baidu.com/direction?origin=latlng:${this.postData.lat},${this.postData.lon}|name:${this.postData.address}&destination=latlng:${detail.lat},${detail.lon}|name:${detail.liftPosition}&mode=driving&origin_region=${this.postData.address}&&destination_region=${detail.liftPosition}&output=html&src=webapp.baidu.openAPIdemo`
        window.location.href = url
      }

      return
    }
    let pathname = ''
    if (item.url) {
      if (this.liftId) {
        pathname = item.url + this.liftId
      } else {
        pathname = item.url
      }
      this.props.history.push({ pathname });
    }

  }

  handleBack = () => {
    this.props.history.goBack()
  }


  render() {
    const { detail, list } = this.state
    const nodes = list.map((item, index) => {
      if (item.show) {
        return (
          <li key={index} onClick={() => this.jump(item)}>
            <img src={item.icon} alt="" />
            <span>{item.text}</span>
          </li>
        )
      } else {
        return ''
      }

    })
    const dateArray = this.dateString.split('')
    const dayStr = this.state.dayStr
    let node = dateArray.map((item, index) => {
      return (
        <span className="employeesBoxTimeSmall" key={index}>{item}</span>
      )
    })
    return (
      <div className="employees commonBodyBox">
        <div className="employeesHeader">
          <img onClick={this.handleBack} className="employeesHeaderLiftImg" src={closeImg} alt="" />
          <span>{detail.projectName + " " + detail.liftLocation}</span>
          <img src={threePoint} alt="" className="employeesHeaderRightImg" />
        </div>
        <div className="employeesBox">
          <div>
            <div className="employeesBoxTime">
              {node}
            </div>
            <div className="employeesBoxContent">
              <span>星期{dayStr}</span>
              <span>努力工作，是为了更好的生活!</span>
            </div>
          </div>
        </div>
        <div className="employeesFooter">
          <ul>{nodes}</ul>
          <div className="employeesFooterClose" onClick={this.handleBack}>
            <img src={closeImg} alt="" />
          </div>
        </div>
        <Lodding animating={this.state.animating} />
      </div>
    )
  }
}


export default Employees;