import { combineReducers } from 'redux';
import commonR from './common';
import myLiftR from './myLift'
import maintenanceCalendarR from './maintenanceCalendar'
import maintenanceListR from './maintenanceList'
import touristsMaintenanceListR from './touristsMaintenanceList'
import touristsWorkOrderListR from './touristsWorkOrderList'
import documentListTwoR from './documentListTwo'
const appR = combineReducers({
  commonR,
  myLiftR,
  maintenanceCalendarR,
  maintenanceListR,
  touristsMaintenanceListR,
  touristsWorkOrderListR,
  documentListTwoR
});

export default appR;