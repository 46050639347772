import React from 'react';
import { Switch } from 'react-router-dom';
import RouteWithSubRoutes from '../../pages/RouteWithSubRoutes'
import Header from '../Header'
import { connect } from 'react-redux';
import './layout.scss'
class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      headerConfig: {}
    }
  }


  onRef = (ref) => {
    this.child = ref
  }
  rightclick = () => {
    if (this.props.common[this.props.common.pathname].actionType) {
      const add = this.child.submit();
      console.log(add)
    }
  }
  back = () => {
    this.props.history.goBack()
  }
  componentDidMount() {
    const headerConfig = this.props.common[this.props.common.pathname];
    this.setState({
      headerConfig
    })
  }
  render() {
    const headerConfig = this.props.common[this.props.common.pathname]
    console.log(this.props.common)
    return (
      <div>
        <Header {...headerConfig} onRightClick={this.rightclick} back={this.back}/>
        <div className="layoutBox">
          <Switch>
            {this.props.routes.map((route, i) => (
              <RouteWithSubRoutes key={i} {...route} onRef={this.onRef} />
            ))}
          </Switch>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    common: state.commonR
  }
}


export default connect(mapStateToProps)(Layout);