

import React, { useEffect, useRef, useState } from 'react'
import commonAction from '../../actions/common'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import QRCode from 'qrcode'
import './index.scss'
// 
const ErCode = ({ path, dispatch}) => {
  const params = useParams()
  const idRef = useRef(null)
  const imgRef = useRef(null)
  const [imgUrl, setImgUrl] = useState(null)

  const getQRCodeFun = async (url) => {
    const _myQRCode = await QRCode.toDataURL(url)
    return _myQRCode
  }

  const initCode = async () => {
    const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxf387e8f0cf954423&redirect_uri=https://wx.yungon.com/tourists%3FliftCode=${idRef.current}&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect`
    const img = await getQRCodeFun(url)
    setImgUrl(img)
  }

  useEffect(() => {
    idRef.current = params.id
    const pathname = path;
    dispatch({ type: commonAction.setPathname, pathname })
    initCode()
  }, [])
  return <div className='ercodeBox'>
    <img src={imgUrl} alt="" />
  </div>
}

export default connect()(ErCode);