import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import './Tabs.scss'


class Tabs extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentIndex: 0
    }
    this.swiper = null
    this.clientWidth = 0
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this)
    }
  }

  clickSwiper = (e) => {
    if (String(e.clickedIndex) === "undefined") {
      return
    }
    this.setState({
      currentIndex: e.clickedIndex
    })
    this.props.changeTab(e.clickedIndex)

  }

  onSwiper = (swiper) => {
    this.swiper = swiper
  }

  changeSwuper = (index) => {
    this.swiper.slideTo(index)
    this.setState({
      currentIndex: index
    })
  }


  render() {
    const list = this.props.list
    let ele = <div></div>
    if (list && list.length) {
      ele = (
        <div className="tabs">
          <Swiper
            spaceBetween={0}
            slidesPerView={5}
            onClick={(e) => this.clickSwiper(e)}
            slideToClickedSlide={true}
            watchSlidesVisibility={true}
            watchSlidesProgress={true}
            onSwiper={(swiper) => this.onSwiper(swiper)}
            centeredSlides={list.length>6}
            centeredSlidesBounds={list.length>6}
          >
            {
              list.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div className="tabsItem">
                      <span className={this.state.currentIndex === index ? 'active' : ''}>{item}</span>
                    </div>
                  </SwiperSlide>
                )
              })
            }
          </Swiper>
        </div>
      )
    }
    return ele
  }
}

export default Tabs;