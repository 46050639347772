import React from 'react'
import commonAction from '@/actions/common'
import { connect } from 'react-redux'
import './WorkOrderDetails.scss'
import WhiteSpace from '@/components/WhiteSpace'
import { getOrderDetail, saveOrder, startOrder } from '@/api/TryOutPage/WorkOrderDetails'
import { batchUploadFileByBase64, uploadFileByBase64, getWechatConfig, wxValidationPermissions } from '@/api/Sys'
import Tabs from '@/components/Tabs'
import MainSwiper from './components/MainSwiper'
import { ImagePicker } from 'antd-mobile';
import Drawer from '@/components/Drawer'
import Signature from '@/components/Signature'
import wx from 'weixin-js-sdk'
import Lodding from '@/components/Lodding'

class WorkOrderDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      detail: {},
      locationDTOList: [],
      locationDTOListName: [],
      scenePhoto: [],
      orderPhoto: [],
      open: false,
      lat: 0,
      address: "",
      animating: false,
      status: 4, //工单状态
      signatureImage: '',
      remark: '',
      replacementParts: ''

    }
    this.postData = {}
    // 工单状态 2 中途离开 4 结束
    this.submitStatus = 2
  }
  componentDidMount() {
    const pathname = this.props.path;
    this.props.dispatch({ type: commonAction.setPathname, pathname })
    this.props.onRef(this);
    // this.startOrderFun(this.props.match.params.id * 1)
    this.getOrderDetailFun(this.props.match.params.id)

      this.getWechatConfigFun()
    
  }

  // 开始工单
  startOrderFun = (orderId) => {
    startOrder({ orderId })
  }
  // 判断是不是ios
  isIos = () => {
    const u = navigator.userAgent;
    return u.indexOf("iPhone") > -1 || u.indexOf("Mac OS") > -1;
  }
  submit = () => {
    this.props.history.push({ pathname: `/layout/log/${this.props.match.params.id}` });
  }

  async getOrderDetailFun(orderNo) {
    const res = await getOrderDetail({ orderNo })
    if (res.code === 200) {
      let tabsNames = []
      res.data.locationDTOList.forEach(item => {
        tabsNames.push(item.locationName)
      })
      let liveImageDataList = []
      res.data.liveImageDataList.forEach((item, index) => {
        liveImageDataList.push({
          id: index,
          url: item.imageUrl
        })
      })
      let orderImageDataList = []
      res.data.orderImageDataList.forEach((item, index) => {
        orderImageDataList.push({
          id: index,
          url: item.imageUrl
        })
      })
      let signatureImage = ''
      if (res.data.signatureImageDataList && res.data.signatureImageDataList.length) {
        signatureImage = res.data.signatureImageDataList[0].imageUrl
      }
      this.setState({
        detail: res.data,
        locationDTOList: res.data.locationDTOList,
        locationDTOListName: tabsNames,
        scenePhoto: liveImageDataList,
        orderPhoto: orderImageDataList,
        status: res.data.status,
        signatureImage
      })
    }
  }

  onMainSwiperRef = (ref) => {
    this.mainSwiper = ref
  }

  onTabsRef = (ref) => {
    this.tabs = ref
  }

  changeTab = (index) => {
    if (this.mainSwiper) {
      this.mainSwiper.changeSlide(index);
    }
  }

  touchIndex = (index) => {
    this.tabs.changeSwuper(index)
  }


  scenePhotoChange = (files, type, index) => {
    this.setState({
      scenePhoto: files
    })
  }

  orderPhotoChange = (files, type, index) => {
    this.setState({
      orderPhoto: files
    })
  }

  // 弹出签名弹窗
  clickSubmit = async () => {
    this.setState({
      open: true
    })

  }

  // 签完字确定后保存数据
  save = async () => {
    this.postData.submitStatus = this.submitStatus
    this.postData.remark = this.state.remark
    this.postData.replacementParts = this.state.replacementParts
    const res = await saveOrder(this.postData)
    if (res.code === 200) {
      this.setState({
        animating: false,
        open: false
      }, () => {
        this.props.history.goBack()
      })
    }

  }

  // 关闭弹窗
  handleClose = async () => {
    this.setState({
      open: false
    })
  }

  // 获取签名的base64 然后提交服务器
  getSignature = async (base64) => {
    this.setState({
      animating: true
    })
    const res1 = await this.submitBase64List()
    if (res1) {
      const res = await uploadFileByBase64(base64)
      if (res.code === 200) {
        this.submitStatus = 4
        this.postData.signatureImageDataList = [{ imageUrl: res.data.url }]
        this.getLocation()
      }
    }



  }
  // 提交base64图片到服务器
  submitBase64List = async () => {
    this.postData = { ...this.state.detail }
    this.postData.locationDTOList = await this.mainSwiper.getNewData()
    let orderImageDataList = []
    let liveImageDataList = []
    let orderImageBase64ListFinish = []
    let liveImageBase64ListFinish = []
    if (this.state.orderPhoto && this.state.orderPhoto.length) {
      const orderImageBase64List = []

      this.state.orderPhoto.forEach(item => {
        if (item.file) {
          orderImageBase64List.push(item.url)
        } else {
          orderImageBase64ListFinish.push({
            imageType: 2,
            imageUrl: item.url
          })
        }
      })
      const orderImageDataListRes = await batchUploadFileByBase64({ base64List: orderImageBase64List })
      if (orderImageDataListRes.code === 200) {
        orderImageDataList = orderImageDataListRes.data.map(item => {
          const obj = {
            imageType: 2,
            imageUrl: item.url
          }
          return obj
        })
      }
    }
    if (this.state.scenePhoto && this.state.scenePhoto.length) {
      const liveImageBase64List = []

      this.state.scenePhoto.forEach(item => {
        if (item.file) {
          liveImageBase64List.push(item.url)
        } else {
          liveImageBase64ListFinish.push({
            imageType: 1,
            imageUrl: item.url
          })
        }
      })
      const liveImageDataListRes = await batchUploadFileByBase64({ base64List: liveImageBase64List })
      if (liveImageDataListRes.code === 200) {
        liveImageDataList = liveImageDataListRes.data.map(item => {
          const obj = {
            imageType: 1,
            imageUrl: item.url
          }
          return obj
        })
      }
    }
    this.postData.orderImageDataList = orderImageBase64ListFinish.concat(orderImageDataList)
    this.postData.liveImageDataList = liveImageBase64ListFinish.concat(liveImageDataList)
    return true
  }
  // 中途离开
  liveSubmit = async () => {
    this.setState({
      animating: true
    })
    this.submitStatus = 2
    if (await this.submitBase64List()) {
      this.getLocation()
    }
  }

  // 获取授权
  getWechatConfigFun = async () => {
    let url = window.location.href
    if (this.isIos()) {
      url = localStorage.getItem('url')
    }
    const res = await getWechatConfig(url)
    if (res.code === 200) {
      const data = { ...res.data }
      data.jsApiList = ['getLocation']
      wxValidationPermissions(data)
    }
  }

  // 获取地理位置
  getLocation = () => {
    const that = this
    wx.getLocation({
      type: 'wgs84',
      success: (res) => {
        const BMap = window.BMap
        const ggPoint = new BMap.Point(res.longitude, res.latitude)
        var convertor = new BMap.Convertor();
        var pointArr = [];
        pointArr.push(ggPoint);
        const geoc = new BMap.Geocoder()
        convertor.translate(pointArr, 1, 5, (res) => {
          if (res.status === 0) {
            that.postData.lon = res.points[0].lng
            that.postData.lat = res.points[0].lat
            geoc.getLocation(res.points[0], function (rs) {
              that.setState({
                address: rs.address
              })
              that.postData.address = rs.address
              that.save()
            })
          } else {
            that.save()
          }
        })
      },
      error: (error) => {
        that.save()
      }

    })
  }

  // 备注信息
  handleRemarkChange = (event)=> {
    this.setState({remark: event.target.value});
  }
  
  // 更换配件信息
  handleReplacementParts= (event)=> {
    this.setState({replacementParts: event.target.value});
  }








  render() {
    const { detail, scenePhoto, orderPhoto, locationDTOList } = this.state
    return (
      <div className="workOrderDetails">
        <div className="top">
          <ul>
            <li>
              工单编号<span>{detail.orderNo}</span>
            </li>
            <li>
              电梯编码<span>{detail.liftCode}</span>
            </li>
            <li>
              电梯位置<span>{detail.liftLocation}</span>
            </li>
            <li>
              计划时间<span>{detail.planTime}</span>
            </li>
            <li>
              保养类型<span>{detail.maintainTypeName}</span>
            </li>
          </ul>
        </div>
        <WhiteSpace />
        <div className="bottom">
          <Tabs list={this.state.locationDTOListName} changeTab={this.changeTab} onRef={this.onTabsRef} />
          <ul className="bottomItem">
            <li><i></i><span>正常</span></li>
            <li><i></i><span>出错</span></li>
            <li><i></i><span>无此项</span></li>
            <li><i></i><span>问题已整改</span></li>
            <li><i></i><span>已更换零件</span></li>
          </ul>
          <div className="mainSwiperBox">
            <MainSwiper status={this.state.status} onRef={this.onMainSwiperRef} list={locationDTOList} touchIndex={this.touchIndex} />
          </div>
          <div className="textAreaBox">
            <h6>备注信息</h6>
            <textarea placeholder="无" disabled={this.state.status === 4} value={this.state.remark} onChange={this.handleRemarkChange} />
          </div>
          <div className="textAreaBox">
            <h6>更换配件信息</h6>
            <textarea placeholder="无" disabled={this.state.status === 4} value={this.state.replacementParts} onChange={this.handleReplacementParts} />
          </div>
          {
            this.state.status === 4 && !scenePhoto.length ? "" : (
              <div className="fileUploadBox">
                <h6>现场照片</h6>
                <ImagePicker
                  className={this.state.status === 4 ? "displayClose" : ""}
                  files={scenePhoto}
                  onChange={this.scenePhotoChange}
                  selectable={scenePhoto.length < 5 && this.state.status !== 4}
                  multiple={true}
                  accept="image/gif,image/jpeg,image/jpg,image/png"
                />
              </div>
            )
          }

          {
            this.state.status === 4 && !orderPhoto.length ? "" : (
              <div className="fileUploadBox">
                <h6>工单照片</h6>
                <ImagePicker
                  className={this.state.status === 4 ? "displayClose" : ""}
                  files={orderPhoto}
                  onChange={this.orderPhotoChange}
                  selectable={orderPhoto.length < 5 && this.state.status !== 4}
                  multiple={true}
                  accept="image/gif,image/jpeg,image/jpg,image/png"
                />
              </div>)
          }

          {
            this.state.signatureImage ?
              (<div className="fileUploadBox">
                <h6>处理人</h6>
                <img className="signatureImage" src={this.state.signatureImage} alt="" />
              </div>) : ''
          }
        </div>
        {
          this.state.status === 4 ? '' : (
            <div>
              <div className="footer" >
                <div onClick={this.liveSubmit}>中途离开</div>
                <div onClick={this.clickSubmit}>提交</div>
              </div>
              <Drawer direction="bottom" handleClose={this.handleClose} open={this.state.open}>
                <Signature getSignature={this.getSignature} />
              </Drawer>
              <Lodding animating={this.state.animating} />
            </div>
          )
        }
      </div>
    )
  }
}

export default connect()(WorkOrderDetails);