
const maintenanceCalendarState = {
  params: {
    keyword: '',
    pageIndex: 1,
    pageSize: 20,
    pageType: 1,
    statisticsDate: ''
  },
  totalPage: 0,
  // 今日维保 今日超期的列表数据
  orderPageList: [],
  scrollHight: 0
}

export default function maintenanceCalendarR(state = maintenanceCalendarState, action) {
  switch (action.type) {
    case "setScrollHight":
      return {
        ...state,
        scrollHight: action.scrollHight,
      }
    case "setMaintenanceCalendar":
      return {
        ...state,
        params: { ...action.payload.params },
        orderPageList: [...action.payload.list],
        totalPage: action.payload.totalPage
      }
    default:
      return state;
  }
}