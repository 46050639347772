import Layout from '../components/Layout'
import MyLift from '../pages/MyLift'
import Home from '../pages/Home'
import Login from '../pages/Login'
import MyLiftDetail from '../pages/MyLiftDetail'
import MyLiftEdit from '../pages/MyLiftEdit'
import Index from '../pages/Index'
import MaintenanceCalendar from '../pages/MaintenanceCalendar'
import MaintenanceList from '../pages/MaintenanceList'
import WorkOrderDetails from '../pages/WorkOrderDetails'
import Log from '../pages/Log'
import ElevatorMaintenance from '../pages/ElevatorMaintenance'
import Select from '../components/Select'
import Employees from '../pages/Employees'
import ToBeSigned from '../pages/ToBeSigned'



// 游客试用
import Tourists from '../pages/Tourists'
import TouristsMyLiftDetail from '../pages/TryOutPage/MyLiftDetail'
import EmergencyRescue from '../pages/TryOutPage/EmergencyRescue'
import UseUnit from '../pages/TryOutPage/UseUnit'
import TouristsWorkOrderList from '../pages/TryOutPage/WorkOrderList'
import TouristsWorkOrderDetails from '../pages/TryOutPage/WorkOrderDetails'
import TouristsMaintenanceList from '../pages/TryOutPage/MaintenanceList'
import TouristsMaintenanceOrderDetails from '../pages/TryOutPage/ElevatorMaintenance'
import TouristslayoutLog from '../pages/TryOutPage/Log'

import LiftLocation from '../pages/LiftLocation'
import ShouQuan from '../pages/ShouQuan'
import ErCode from '../pages/ErCode'
import TechnicalDocument from '../pages/TechnicalDocument'
import AnnualInspectionDue from '../pages/AnnualInspectionDue/index.js'
import AnnualInspectionAndOverhaul from '../pages/AnnualInspectionAndOverhaul/index.js'
import DocumentList from '../pages/DocumentList'
import DocumentListTwo from '../pages/DocumentListTwo'

const routes = [
  {
    path: '/',
    exact: true,
    component: Index,
  },
  {
    path: '/home',
    exact: true,
    component: Home,
  },
  {
    path: '/layout',
    component: Layout,
    routes: [
      // 电梯列表
      {
        path: '/layout/mylift',
        component: MyLift
      },
      // 电梯列表--任务指派
      {
        path: '/layout/mylift2',
        component: MyLift
      },
      // 电梯详情
      {
        path: '/layout/myliftdetail/:id',
        component: MyLiftDetail
      },
      // 电梯编辑
      {
        path: '/layout/myliftedit/:id',
        component: MyLiftEdit
      },
      // 维保日历
      {
        path: '/layout/calendar',
        component: MaintenanceCalendar
      },
      // 维保列表
      {
        path: '/layout/maintenanceList',
        component: MaintenanceList
      },
      // 工单详情
      {
        path: '/layout/workOrderDetails/:id',
        component: WorkOrderDetails
      },
      // 日志
      {
        path: '/layout/log/:id',
        component: Log
      },
      // 电梯维修
      {
        path: '/layout/elevatorMaintenance',
        component: ElevatorMaintenance
      },
      {
        path: '/layout/select',
        component: Select
      },
      {
        path: '/layout/toBeSigned',
        component: ToBeSigned
      }
    ]
  },
  {
    path: '/login',
    exact: true,
    component: Login,
  },
  {
    path: '/shouquan',
    exact: true,
    component: ShouQuan,
  },
  {
    path: '/tourists',
    component: Tourists,
  },

  {
    path: '/employees',
    component: Employees,
  },
  {
    path: '/liftemployees/:liftCode',
    component: Employees,
  },
  // 游客进来
  {
    path: '/touristslayout',
    component: Layout,
    routes: [
      {
        path: '/touristslayout/myLiftDetail/:id',
        component: TouristsMyLiftDetail,
      },
      // 紧急救援
      {
        path: '/touristslayout/emergencyRescue/:id',
        component: EmergencyRescue,
      },
      // 使用单位
      {
        path: '/touristslayout/useUnit/:id',
        component: UseUnit,
      },
      // 维保工单列表
      {
        path: '/touristslayout/workOrderList/:id',
        component: TouristsWorkOrderList,
      },
      // 维保工单详情
      {
        path: '/touristslayout/workOrderDetail/:id',
        component: TouristsWorkOrderDetails,
      },
      // 维修工单列表
      {
        path: '/touristslayout/maintenanceList/:id',
        component: TouristsMaintenanceList,
      },
      // 维修工单详情
      {
        path: '/touristslayout/maintenanceOrderDetail/:id',
        component: TouristsMaintenanceOrderDetails,
      },
      // 日志
      {
        path: '/touristslayout/log/:id',
        component: TouristslayoutLog
      },
    ]
  },
  // 员工进来
  {
    path: '/employeeslayout',
    component: Layout,
    routes: [
      // 维修
      {
        path: '/employeeslayout/elevatorMaintenance/:id',
        component: ElevatorMaintenance,
      },
      // 电梯详情
      {
        path: '/employeeslayout/myLiftDetail/:id',
        component: MyLiftDetail,
      },
      // 位置
      {
        path: '/employeeslayout/liftLocation/:id',
        component: LiftLocation,
      },
      {
        path: '/employeeslayout/ercode/:id',
        component: ErCode,
      },
      {
        path: '/employeeslayout/document/:id',
        component: TechnicalDocument,
      },
      {
        path: '/employeeslayout/documentList',
        component: DocumentList,
      },
      {
        path: '/employeeslayout/documentListTwo/:id',
        component: DocumentListTwo,
      },

      // 年检到期
      {
        path: '/employeeslayout/annualInspectionDue/:id',
        component: AnnualInspectionDue,
      },
      // 年检大修
      {
        path: '/employeeslayout/annualInspectionAndOverhaul/:id',
        component: AnnualInspectionAndOverhaul,
      },
    ]
  },
]

export default routes;