
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import routes from '../../router';
import RouteWithSubRoutes from '../../pages/RouteWithSubRoutes'

export default function RouteConfigExample() {
  return (
    <Router>
      <div>
        
        <Switch>
          {
            routes.map( function(route, i) {
              return <RouteWithSubRoutes key={i} { ...route } />
            })
          }
        </Switch>
      </div>
    </Router>
  )
}