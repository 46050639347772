import React from 'react'
import './Tourists.scss'
import closeImg from '../../static/images/close.png'
import threePoint from '../../static/images/threePoint.png'
import banner from '../../static/images/liftBg.png'
import elevatorInformation from '../../static/images/elevatorInformation.png'
import maintenanceHistory from '../../static/images/maintenanceHistory.png'
import upkeepHistory from '../../static/images/upkeepHistory.png'
import useUnit from '../../static/images/useUnit.png'
import regulatory from '../../static/images/regulatory.png'
import safetyCommonSense from '../../static/images/safetyCommonSense.png'
import warrantyRecord from '../../static/images/warrantyRecord.png'
import elevatorWarranty from '../../static/images/elevatorWarranty.png'
import { getLiftDetail, bindLift } from '@/api/TryOutPage/MyLiftDetail'
import { Toast, Modal } from 'antd-mobile'
const list1 = [
  {
    icon: elevatorInformation,
    text: '电梯信息',
    url: '/touristslayout/myLiftDetail/'
  },
  {
    icon: maintenanceHistory,
    text: '维保历史',
    url: '/touristslayout/workOrderList/'
  },
  {
    icon: upkeepHistory,
    text: '维修历史',
    url: '/touristslayout/maintenanceList/'
  },
  // {
  //   icon: upkeepHistory,
  //   text: '保养历史'
  // },
  {
    icon: useUnit,
    text: '使用单位信息',
    url: '/touristslayout/useUnit/'
  },
  // {
  //   icon: regulatory,
  //   text: '监管公示'
  // },
  // {
  //   icon: safetyCommonSense,
  //   text: '安全常识'
  // }
]
const list2 = [
  // {
  //   icon: warrantyRecord,
  //   text: '保修记录'
  // },
  // {
  //   icon: elevatorWarranty,
  //   text: '电梯保修'
  // },
  {
    icon: elevatorWarranty,
    text: '紧急救援',
    url: '/touristslayout/emergencyRescue/'
  },
  {
    icon: warrantyRecord,
    text: '绑定微信'
  }
]
class Tourists extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      detail: {
        projectName: '',
        liftLocation: ''
      }
    }
    this.liftId = null
    this.code = null
  }

  componentWillMount() {
    const liftCode = this.getQueryVariable('liftCode')
    this.code = this.getQueryVariable('code')
    console.log(this.code, 'this.code')
    if (liftCode) {
      this.getLiftDetailFun(liftCode)
    }
  }

  getLiftDetailFun = async (liftCode) => {
    const res = await getLiftDetail({ liftCode });
    if (res.code === 200 && res.data) {
      let location = res.data.liftLocation || ''
      let projectName = res.data.projectName || ''
      document.title = projectName + ' ' + location
      this.setState({
        detail: res.data
      })
      this.liftId = res.data.liftId
    }
  }

  getQueryVariable = (variable) => {
    var query = this.props.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] === variable) { return pair[1]; }
    }
    return (false);
  }


  jump = (item) => {
    let pathname = ''
    const that = this
    if (item.url) {
      if (this.liftId) {
        pathname = item.url + this.liftId
      } else {
        pathname = item.url
      }
      this.props.history.push({ pathname });
    } else if (item.text === '绑定微信') {
      Modal.alert('绑定微信', '确定绑定微信吗？', [
        { text: '取消', onPress:  () => console.log('cancel') },
        { text: '确定', onPress: async() => {
          const code = that.getQueryVariable('code')
          const params = {
            code,
            liftId: that.liftId
          }
          console.log(params)
          const res = await bindLift(params)
          if (res.code === 200) {
            Toast.success('绑定成功', 3)
          } else {
            Toast.fail(res.message, 3)
          }
          
        } },
      ])
    }

  }



  handleBack = () => {
    Toast.success(this.props.match.params.liftCode)
    this.props.history.goBack()
  }
  render() {
    const detail = this.state.detail
    const nodes = list1.map((item, index) => {
      return (
        <li key={index} onClick={() => this.jump(item)}>
          <img src={item.icon} alt="" />
          <span>{item.text}</span>
        </li>
      )
    })
    const nodes2 = list2.map((item, index) => {
      return (
        <li key={index} onClick={() => this.jump(item)}>
          <img src={item.icon} alt="" />
          <span>{item.text}</span>
        </li>
      )
    })
    return (
      
      <div className="tourists commonBodyBox">
        {/* <div className="touristsHeader">
          <img onClick={this.handleBack} className="touristsHeaderLiftImg" src={closeImg} alt="" />
          <span>{detail.projectName + " " + detail.liftLocation}</span>
          <img src={threePoint} alt="" className="touristsHeaderRightImg" />
        </div> */}
        <div className="touristsBox">
          <div className="touristsBI">
            <img src={banner} alt="" />
            <span>{detail.projectName + " " + detail.liftLocation}</span>
          </div>
          <div className="touristsDetail">
            <h1>信息公示</h1>
            <ul>
              {nodes}
            </ul>
          </div>
          <div className="touristsDetail">
            <h1>信息公示</h1>
            <ul>
              {nodes2}
            </ul>
          </div>
        </div>

      </div>
    )
  }
}

export default Tourists;