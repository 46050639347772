import React from 'react'

import TweenOne from 'rc-tween-one';
import PropTypes from 'prop-types';
import AnimationHeader from '../../components/AnimationHeader'
import cookies from '../../libs/util.cookies'
import { wxLogin } from '../../api/Sys/index'
import './index.scss'
import { getWechatConfig, wxValidationPermissions } from '../../api/Sys'
import { Toast } from 'antd-mobile';
function Demo(props) {
  return (
    <TweenOne
      animation={{
        x: 200,
        scale: 0.5,
        rotate: 120,
        yoyo: true, // demo 演示需要
        repeat: -1, // demo 演示需要
        duration: 1000
      }}
      paused={props.paused}
      style={{ transform: 'translateX(-80px)' }}
      className="code-box-shape"
    />
  );
}


class Index extends React.Component {
  constructor(props) {
    super(props)
    this.code = ''
  }
  async componentWillMount() {
    let url = window.location.href.split("#")[0];
    localStorage.setItem('url', url)
    if (this.props.location.search) {
      const search = this.props.location.search
      this.code = search.substring(search.indexOf('=') + 1, search.indexOf('&'))
    }

    if (this.code) {
      Toast.loading("加载中")
      const res = await wxLogin({ wxCode: this.code });
      if (res.code === 200) {
        if (!res.data.token && res.data.openId) {
          this.props.history.replace({pathname:'/login', state: {wxAppNickname: res.data.wxAppNickname, openId: res.data.openId}})
        } else {
          cookies.set('token', res.data.token, { expires: 15 })
          this.props.history.replace({pathname:'/home'})
        }
      } else {
        Toast.hide()
        Toast.info("微信登陆失败")
        this.props.history.replace({pathname:'/login', state: {}})
        Toast.hide()
      }
      Toast.hide()
      
    }
  }
  componentDidMount() {
    // setTimeout(() => {
    //   this.props.history.push('/login');
    // }, 3000)
    
  }

  // 获取授权
  getWechatConfigFun = async () => {
    let url = window.location.href
    const res = await getWechatConfig(url)
    if (res.code === 200) {
      const data = { ...res.data }
      data.jsApiList = ['getLocation', 'scanQRCode']
      wxValidationPermissions(data)
    }
  }

  isIos = () => {
    const u = navigator.userAgent;
    return u.indexOf("iPhone") > -1 || u.indexOf("Mac OS") > -1;
   }
  render() {
    return (
      <div>
        <AnimationHeader />
        {/* <Demo /> */}
        {/* <a href="https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx7e8157fa06dd4ae9&redirect_uri=https://wx.phpstu.cn&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect">tiao</a> */}
      </div>
    )
  }
}

Demo.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  paused: PropTypes.bool,
};

export default Index;