import React from 'react'
import './index.scss'
import { connect } from 'react-redux'
import commonAction from '../../actions/common'
import { Picker, List, ImagePicker, Toast, DatePicker } from 'antd-mobile';
import { getOrderLiftDetail, saveOrder, getOrderDetail } from '@/api/AnnualInspectionDue';
import Lodding from '../../components/Lodding'
import { getLiftDetail } from '@/api/MyLiftDetail'
import transformFile from '@/libs/imageCompression'
import moment from 'moment/moment';
import { batchUploadFileByBase64 } from '../../api/Sys'


const data = [{
  url: 'https://zos.alipayobjects.com/rmsportal/PZUUCKTRIHWiZSY.jpeg',
  id: '2121',
},];
class AnnualInspectionDue extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      files: data,
      dayAmount: 15, //天数
      scenePhoto: [], //现场图片
      animating: false, //控制loding
      detail: {},
      liftDetail: {}, //电梯信息
      annualInspectionResult: ['合格'],
      startTime: '',
      payStatusList: [
        {
          value: '合格',
          label: '合格'
        },
        {
          value: '不合格',
          label: '不合格'
        }
      ]
    }
    this.liftId = '' //电梯id
    this.repairOrderId = ''
    this.postData = {} //数据
    this.orderDetail = {} //工单详情
    this.liftCode = ''
    this.itemStyle = {
      'height': '60px',
    }

  }
  componentDidMount() {
    const pathname = this.props.path;
    this.props.dispatch({ type: commonAction.setPathname, pathname })
    this.props.onRef(this);
    this.liftCode = this.props.match.params.id
    this.getLiftDetailFun(this.liftCode)
  }

  // 判断是不是ios
  isIos = () => {
    const u = navigator.userAgent;
    return u.indexOf("iPhone") > -1 || u.indexOf("Mac OS") > -1;
  }
  // 为了获取repairOrderId
  getLiftDetailFun = async (liftCode) => {
    const res = await getLiftDetail({ liftCode });
    if (res.code === 200) {
      this.liftId = res.data.liftId
      this.getOrderLiftDetailFun()
      console.log(res.data)
      if (res.data.annualInspectionOrderId) {
        this.repairOrderId = res.data.annualInspectionOrderId

        this.getOrderDetailFun()
      }
    }
  }










  // 获取工单中电梯信息
  getOrderLiftDetailFun = async () => {
    const res = await getOrderLiftDetail({ liftId: this.liftId });
    if (res.code === 200) {
      this.setState({
        liftDetail: res.data
      })
    }
  }

  //获取工单详情
  getOrderDetailFun = async () => {
    const res = await getOrderDetail({ orderId: this.repairOrderId });
    if (res.code === 200) {
      const orderDetail = res.data
      let { dayAmount, startTime, solution, faultDescription, materialRecordList, orderTeamworkParamList, payStatus } = orderDetail
      let liveImageDataList = []
      orderDetail.liveImageDataList.forEach((item, index) => {
        liveImageDataList.push({
          id: index,
          url: item.imageUrl
        })
      })
      if (startTime) {
        startTime = new Date(startTime)
      }
      this.setState({
        annualInspectionResult: [orderDetail.annualInspectionResult],
        dayAmount,
        startTime,
        scenePhoto: liveImageDataList,
      })
    }
  }







  submit = () => {
    if (!this.repairOrderId) {
      Toast.info("暂无日志信息")
      return
    }
    this.props.history.push({ pathname: `/layout/log/${this.repairOrderId}` });
  }



  handleRemarkChange = (event, type) => {
    this.setState({ [type]: event.target.value });
  }


  // 选择现场图片
  scenePhotoChange = (files, type, index) => {
    this.setState({
      scenePhoto: files
    })
  }


  // 弹出签名弹窗
  clickSubmit = async () => {
    this.save()

  }

  // 提交base64图片到服务器
  submitBase64List = async () => {
    let liveImageDataList = []
    let liveImageBase64ListFinish = []

    if (this.state.scenePhoto && this.state.scenePhoto.length) {
      const liveImageBase64List = []

      for (let i = 0; i < this.state.scenePhoto.length; i++) {
        if (this.state.scenePhoto[i].file) {
          liveImageBase64List.push(await transformFile(this.state.scenePhoto[i]))
        } else {
          liveImageBase64ListFinish.push({
            imageType: 2,
            imageUrl: this.state.scenePhoto[i].url
          })
        }
      }
      const liveImageDataListRes = await batchUploadFileByBase64({ base64List: liveImageBase64List })
      if (liveImageDataListRes.code === 200) {
        liveImageDataList = liveImageDataListRes.data.map(item => {
          const obj = {
            imageType: 1,
            imageUrl: item.url
          }
          return obj
        })
      }
    }
    this.postData.liveImageDataList = liveImageBase64ListFinish.concat(liveImageDataList)
    return true
  }

  // 签完字确定后保存数据
  save = async () => {
    Toast.loading('提交中', 0)
    const res1 = await this.submitBase64List()
    if (res1) {
      this.postData.annualInspectionResult = this.state.annualInspectionResult[0]
      this.postData.dayAmount = this.state.dayAmount;
      this.postData.liftId = this.state.liftDetail.liftId;

      console.log(this.state.liftDetail, this.state.detail)
      if (this.state.startTime) {
        this.postData.startTime = moment(this.state.startTime).format('YYYY-MM-DD');
      }
    }


    const res = await saveOrder(this.postData)
    Toast.hide()
    if (res.code === 200) {
      this.setState({
        animating: false,
        open: false
      }, () => {
        this.props.history.goBack()
      })
    } else {
      this.setState({
        animating: false,
        open: false
      })
      Toast.fail("提交失败")
    }
  }


  render() {
    const { scenePhoto, liftDetail, annualInspectionResult, payStatusList } = this.state


    return (
      <div className="annualInspectionDue">
        <div className="top">
          <ul>
            <li>
              项目名称<span>{liftDetail.projectName}</span>
            </li>
            <li>
              使用单位<span>{liftDetail.propertyUnitName}</span>
            </li>
            <li>
              使用证号<span>{liftDetail.useCertificateNumber}</span>
            </li>
            <li>
              电梯位置<span>{liftDetail.liftLocation}</span>
            </li>

          </ul>
        </div>
        <h1>维修记录</h1>
        <div className="information">
          <div className="pickerBox">
            <Picker data={payStatusList} cols={1} className="forss" value={annualInspectionResult} onChange={v => this.setState({ annualInspectionResult: v })}>
              <List.Item arrow="horizontal">年检结果</List.Item>
            </Picker>
          </div>
          <div className="information-item information-item-point">
            <span>维修人员</span>
            <span>{liftDetail.currentUserName}</span>
          </div>
          <div className="information-item information-item-point">
            <span>天数</span>
            <input type='number' value={this.state.dayAmount} onChange={(e) => { this.handleRemarkChange(e, 'dayAmount') }} />

          </div>
          <div className="pickerBox">
            <DatePicker
              className='annualInspectionDueDate'
              mode="date"
              title="时间"
              value={this.state.startTime}
              onChange={date => this.setState({ startTime: date })}
            >
              <List.Item arrow="horizontal">时间</List.Item>

            </DatePicker>
          </div>
          <div className="fileUploadBox">
            <h6>添加图片</h6>
            <ImagePicker

              files={scenePhoto}
              onChange={this.scenePhotoChange}
              selectable={scenePhoto.length < 5}
              multiple={true}
              accept="image/gif,image/jpeg,image/jpg,image/png"
            />
          </div>



        </div>

        <div>
          <div className="footer" >
            <div onClick={this.clickSubmit}>提交</div>
          </div>
          <Lodding animating={this.state.animating} />
        </div>

      </div>

    )
  }
}

export default connect()(AnnualInspectionDue);