import { fetch } from '../../plugin/axios'
import wx from 'weixin-js-sdk'


const serverName = '/auth'

/**
 * 根据code获取登陆状态wxValidationPermissions
 * @param {wxCode} data 
 */
export function wxLogin(data) {
  return fetch({
    url: serverName + '/auth/wxLogin',
    method: 'POST',
    data
  })
}


/**
 * 登陆
 * @param {name, password} data 
 */
export function authLogin(data) {
  return fetch({
    url: serverName + '/auth/login',
    method: 'POST',
    data
  })
}


/**
 * base64文件上传
 * @param {base64} data 
 */
export function uploadFileByBase64(base64) {
  return fetch({
    url: '/foreign/storage/uploadFileByBase64',
    method: 'POST',
    data: { base64 }
  })
}


/**
 * base64文件批量上传
 * @param {base64List} data 
 */
export function batchUploadFileByBase64(data) {
  return fetch({
    url: '/foreign/storage/batchUploadFileByBase64',
    method: 'POST',
    data,
  })
}



/**
 * base64文件批量上传
 * @param {base64List} data 
 */
export function getWechatConfig(url) {
  return fetch({
    url: '/maintain/app/wechat/getWechatConfig',
    method: 'GET',
    params: {
      url
    },
  })
}



/**
 * 通过config接口注入权限验证配置
 * @param {obj} data 
 */
export function wxValidationPermissions(data) {
  return wx.config({
    debug: false,
    appId: data.appId,
    timestamp: data.timestamp,
    nonceStr: data.nonceStr,
    signature: data.signature,
    jsApiList: data.jsApiList
  })
}


/**
 * 获取字典
 * @param {Object} params 
 */
export function getDictionaryList(params) {
  return fetch({
    url: '/auth/dictionary/getDictionaryList',
    method: 'GET',
    params
  })
}


/**
 * 获取权限
 */
export function getPermissions() {
  return fetch({
    url: '/auth/auth/getPermissions',
    method: 'GET'
  })
}


/**
 * 绑定
 */
 export function bindWx(data) {
  return fetch({
    url: '/auth/customer/bindWx',
    method: 'POST',
    data
  })
}


