import React from 'react';
import WeeklyCalendar from '../../components/WeeklyCalendar'
import commonAction from '../../actions/common'
import { connect } from 'react-redux';
import { getCalendar, getOrderPageList, getCalendarDetailList } from '../../api/MaintenanceCalendar'
import ScrollBox from '../../components/ScrollBox'
import ItemBox from '../../components/ItemBox'
import Drawer from '../../components/Drawer'
import './MaintenanceCalendar.scss'
import Lodding from '../../components/Lodding'
import NoData from '@/components/NoData'

class MaintenanceCalendar extends React.Component {
  constructor(props) {
    super(props);


    this.state = {
      // 今日维保 今日超期
      pageType: 1,
      isCanLoading: true,
      scrollHight: 0,
      list: [],
      calendarData: {
        // 以保养数量
        maintainedAmount: 0,
        // 计划数量
        planAmount: 0,
        // 已维修数量
        repairedAmount: 0
      },
      // 今日维保 今日超期的列表
      orderPageList: [],
      // 弹窗list
      popupList: [],
      open: false,
      animating: false
    };
    this.pageIndex = 1
    this.pageSize = 20
    this.statisticsDate = ''
    this.totalPage = 0
    // 计划 1 维修 3 保养 2
    this.statisticsType = 1
    this.initDate()
  }

  // 初始化当前日期数组
  initDate = () => {
    let needDate = new Date()
    let needYear = needDate.getFullYear()
    let needMonth = this.format(needDate.getMonth() + 1)
    let needDay = this.format(needDate.getDate())
    this.statisticsDate = `${needYear}-${needMonth}-${needDay}`
  }
  format = (num) => {
    let str = num >= 10 ? num : '0' + num
    return str
  }

  componentDidMount() {

  }

  componentWillMount() {
    const pathname = this.props.path;
    this.props.dispatch({ type: commonAction.setPathname, pathname })
    this.props.onRef(this);
    if (this.props.history.action === "POP") {
      this.pageIndex = this.props.maintenanceCalendarState.params.pageIndex
      this.keyword = this.props.maintenanceCalendarState.params.keyword
      this.totalPage = this.props.maintenanceCalendarState.totalPage
      this.setState({
        pageType: this.props.maintenanceCalendarState.params.pageType
      })
      this.statisticsDate = this.props.maintenanceCalendarState.params.statisticsDate

      this.setState((state) => ({
        orderPageList: this.props.maintenanceCalendarState.orderPageList,
        scrollHight: this.props.maintenanceCalendarState.scrollHight
      }))
    } else {
      this.getOrderPageListFun();
    }
    this.getCalendarFun();
  }
  // 获取计划 以保养 以维修
  getCalendarFun = async () => {
    const params = {
      statisticsDate: this.statisticsDate,
    }

    const res = await getCalendar(params);
    if (res.code === 200) {
      this.setState({
        calendarData: res.data
      })
    }
  }

  // 今日维修 今日保养数据
  getOrderPageListFun = async () => {
    this.setState({
      animating: true
    })
    const params = {
      keyword: '',
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      pageType: this.state.pageType,
      statisticsDate: this.statisticsDate
    }
    const res = await getOrderPageList(params)
    if (res.code === 200) {
      this.totalPage = res.data.totalPage;
      this.setState((state) => ({
        orderPageList: state.orderPageList.concat(res.data.list),
        isCanLoading: true,
        animating: false
      }))
      // 缓存搜索数据 今日维修 今日维保
      this.props.dispatch({ type: "setMaintenanceCalendar", payload: { list: this.state.orderPageList, totalPage: res.data.totalPage, params } })
    }
  }

  // 点击计划 保养 维修的弹窗数据
  getCalendarDetailListFun = async () => {
    const params = {
      statisticsDate: this.statisticsDate,
      statisticsType: this.statisticsType,
      pageIndex: 1,
      pageSize: 100
    }
    const res = await getCalendarDetailList(params)
    if (res.code === 200) {
      this.setState({
        open: true,
        popupList: res.data.list
      }, () => {

      })
      this.timmer = setTimeout(() => {
        if (this.refs.scrollBox) {
          this.refs.scrollBox.refresh()
        }

      }, 500);
    }
  }


  submit = () => {
    console.log("子通过父触发兄弟的方法");
    return this.state.name
  }

  handleClickType = (val) => {
    this.pageIndex = 1
    this.setState({
      pageType: val,
      orderPageList: []
    }, () => {
      this.getOrderPageListFun()
    })


  }
  handleClickItem = (val) => {
    this.props.history.push({ pathname: `/layout/workOrderDetails/${val.orderId}` });
  }

  // 切换日期
  getDate = (val) => {
    this.statisticsDate = val.date

    this.getCalendarFun()
    this.handleRefresh()
  }
  // 记录滚动的位置
  setScrollHight = (val) => {
    this.props.dispatch({ type: "setScrollHight", scrollHight: val })
  }


  // 刷新
  handleRefresh = () => {
    this.pageIndex = 1
    this.totalPage = 0
    this.keyword = ''
    this.setState({
      orderPageList: []
    })
    this.setScrollHight(0)
    this.getOrderPageListFun()

  }

  // 上拉加载更多
  handleScroll = () => {
    this.setState({
      isCanLoading: false
    })
    if (this.pageIndex < this.totalPage) {
      this.pageIndex += 1
      this.getOrderPageListFun()
    }
  }

  // 点击计划以保养 以维修弹窗
  clickStatistics = (val) => {
    this.statisticsType = val
    this.getCalendarDetailListFun()
  }
  // 关闭弹窗
  handleClose = () => {
    this.setState({
      open: false
    })
  }


  componentWillUnmount() {
    if (this.timmer) {
      clearTimeout(this.props.timmer)
    }
  }

  render() {


    return (
      <div className="commonBodyBox maintenanceCalendar">
        <WeeklyCalendar getDate={this.getDate} initialDate={this.statisticsDate} />
        <ul className="typeList">
          <li onClick={() => this.clickStatistics(1)} className={1 ? "plan" : ""}>计划{this.state.calendarData.planAmount || 0}台</li>
          <li onClick={() => this.clickStatistics(2)} className={2 ? "maintain" : ""}>已保养{this.state.calendarData.maintainedAmount || 0}台</li>
          <li onClick={() => this.clickStatistics(3)} className={3 ? "service" : ""}>已维修{this.state.calendarData.repairedAmount || 0}台</li>
        </ul>
        <div className="main">
          <ul>
            <li className={this.state.pageType === 1 ? 'active' : ''} onClick={() => { this.handleClickType(1) }}>今日任务</li>
            <li className={this.state.pageType === 2 ? 'active' : ''} onClick={() => { this.handleClickType(2) }}>今日超期</li>
          </ul>
          <div className="maintenanceCalendarScrollBox">
            {
              this.state.orderPageList && this.state.orderPageList.length ? (
                <ScrollBox setScrollHight={this.setScrollHight} handleRefresh={this.handleRefresh} handleScroll={this.handleScroll} isCanLoading={this.state.isCanLoading} scrollHight={this.state.scrollHight}>
                  <ItemBox handleClick={this.handleClickItem} list={this.state.orderPageList} type="MaintenanceList" />
                </ScrollBox>
              ) : <NoData />
            }

          </div>
        </div>
        <Drawer direction="bottom" handleClose={this.handleClose} open={this.state.open}>
          <div className="popuoBox">
            {
              this.state.popupList && this.state.popupList.length ? (
                <ScrollBox ref="scrollBox">
                  <ItemBox handleClick={this.handleClickItem} list={this.state.popupList} type="MaintenanceList" />
                </ScrollBox>
              ) : <NoData />
            }

          </div>
        </Drawer>
        <Lodding animating={this.state.animating} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    maintenanceCalendarState: state.maintenanceCalendarR
  }

}

export default connect(mapStateToProps)(MaintenanceCalendar);