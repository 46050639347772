/**
 * 电梯或者项目列表模块公共样式 目前支持两种显示样式
 */

import React from 'react';
import liftIcon from '../../../static/icons/liftIcon.png'
import './ItemBox.scss'

// 电梯列表
function MyLift({ item }) {
  return (
  <div>
    <div className="itemBoxBottom">
      <span className="position">电梯位置</span>
      <span className="positionDetail">{item.liftLocation}</span>
    </div>
	<div className="itemBoxBottom">
		<span className="position">客户等级</span>
		<span className="positionDetail">{item.grade}</span>
	</div>
  </div>
  )
}

// 维保列表
function MaintenanceLift({ item }) {
  return (
    <div>
      <div className="itemBoxBottom">
        <span className="position">注册代码</span>
        <span className="positionDetail">{item.registerCode}</span>
      </div>
      <div className="itemBoxBottom">
        <span className="position">电梯位置</span>
        <span className="positionDetail">{item.locationName}</span>
      </div>
      <div className="itemBoxBottom">
        <span className="position">保养时间</span>
        <span className="positionDetail">{item.maintainTime}</span>
      </div>
      <div className="itemBoxBottom">
        <span className="position">保养类型</span>
        <span className="positionDetail">{item.maintainTypeName}</span>
      </div>
	  {/* <div className="itemBoxBottom">
	    <span className="position">客户等级</span>
	    <span className="positionDetail">{item.grade}</span>
	  </div> */}
    </div>
  )
}


function formatSeconds(value) {
  if (!value) {
    return ' '
  }
  var theTime = parseInt(value); // 秒
  var middle = 0; // 分
  var hour = 0; // 小时

  if (theTime > 60) {
    middle = parseInt(theTime / 60);
    theTime = parseInt(theTime % 60);
    if (middle > 60) {
      hour = parseInt(middle / 60);
      middle = parseInt(middle % 60);
    }
  }
  var result = "" + parseInt(theTime) + "秒";
  if (middle > 0) {
    result = "" + parseInt(middle) + "分" + result;
  }
  if (hour > 0) {
    result = "" + parseInt(hour) + "小时" + result;
  }
  return result;
}

// 游客工单
function TouristsWorkOrderList({item, type}) {
  return (
    <div className="touristsBox">
      <div className="itemBoxBottom">
        <span className="position">电梯位置</span>
        <span className="positionDetail">{item.liftLocation}</span>
      </div>
      {/* <div className="itemBoxBottom">
        <span className="position">维保员</span>
        <span className="positionDetail">{item.maintainTime}</span>
      </div> */}
      <div className="itemBoxBottom">
        <span className="position">开始时间</span>
        <span className="positionDetail">{item.startTime}</span>
      </div>
      <div className="itemBoxBottom">
        <span className="position">结束时间</span>
        <span className="positionDetail">{item.endTime}</span>
      </div>
      <div className="itemBoxBottom">
    <span className="position">{type==="TouristsWorkOrderList" ? '保养耗时':'维修耗时'}</span>
        <span className="positionDetail">{formatSeconds(item.elapsedTime)}</span>
      </div>
    </div>
  )
}

function getStatusName(status) {
  let name = ''
  switch (status) {
    case 1:
      name = '开始保养'
      break
    case 2:
      name = '中途离开'
      break
    case 3:
      name = '继续保养'
      break
    case 4:
      name = '已完成'
      break
    default:
      name = ''
  }
  return name
}

// 工单状态
function StatusOrder({ item }) {
  return (
    <div className="itemBoxTopRight">
      <span className={"itemBoxTopRight"+item.status}>{getStatusName(item.status)}</span>
    </div>
  )
}


// 电梯状态
function StatusLift({ item }) {
  return (
    <div className="itemBoxTopRight">
      <span className={"itemBoxRunningStatus"+item.runningStatus}>{item.runningStatusName}</span>
    </div>
  )
}



function Project({ item }) {
  return (
    <div className="itemBoxTopLeft">
      <img src={liftIcon} alt="" />
      <span>{item.projectName}</span>
    </div>
  )
}

function Order({ item }) {
  return (
    <div className="itemBoxTopLeft">
      {/* <img src={liftIcon} alt="" /> */}
      <div className="orderBox">
        <span>保养工单&nbsp;</span>
        <span>{item.orderNo}</span>
      </div>
    </div>
  )
}

class ItemBox extends React.Component {

  handleClick = (item) => {
    this.props.handleClick(item);
  }

  render() {

    return (
      <ul id="itemBoxMain">
        {this.props.list.map((item, indx) => {
          return (
            <li className="itemBox" onClick={() => { this.handleClick(item) }} key={indx}>
              <div className="itemBoxTop">
                {this.props.type !== "TouristsWorkOrderList" && <Project item={item} />}
                {this.props.type === "TouristsWorkOrderList" && <Order item={item}/>}
                {this.props.type === "myLift" &&  <StatusLift item={item} /> }
                {(this.props.type === "TouristsWorkOrderList" || this.props.type === "TouristsMaintenanceList") &&  <StatusOrder item={item} />}
              </div>
              {this.props.type === "myLift" && <MyLift item={item} />}
              {this.props.type === "MaintenanceList" && <MaintenanceLift item={item} />}
              {(this.props.type === "TouristsWorkOrderList" || this.props.type === "TouristsMaintenanceList") && <TouristsWorkOrderList item={item} type={this.props.type}/>}
              {/* <div className="itemBoxBottom">
                <span className="position">电梯位置</span>
                <span className="positionDetail">A座二单元</span>
              </div>
              <div className="itemBoxBottom">
                <span className="position">电梯位置</span>
                <span className="positionDetail">A座二单元</span>
              </div>
              <div className="itemBoxBottom">
                <span className="position">电梯位置</span>
                <span className="positionDetail">A座二单元</span>
              </div> */}
            </li>
          )
        })}

      </ul>
    )
  }
}

export default ItemBox;