/**
 * 依据betterScroll写的下拉刷新 无线加载的组件
 */

import React from 'react';
import './ScrollBox.scss';
import BScroll from 'better-scroll';

/**
 * 需要刷新方法 加载方法 设置高度
 */



class ScrollBox extends React.Component {
  constructor(props) {
    super(props);
    this.scrollBox = React.createRef();
    this.scrollBoxScroll = React.createRef();
    this.timeout = null;
    this.scrollTop = 0;
    this.state = {
      totast: '继续下拉刷新'
    }
  }
  handleScroll = () => {
    console.log(this.scrollBox.current.scrollHeight)
    if (!this.props.isCanLoading) {
      return
    }
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    this.timeout = setTimeout(() => {
      if (this.scrollTop < this.scrollBox.current.scrollTop) {
        if (this.scrollBox.current.scrollHeight - this.scrollBox.current.scrollTop - this.scrollBox.current.clientHeight < 200) {
          this.props.handleScroll(this.scrollBox.current.scrollTop);
        }
      }
      this.scrollTop = this.scrollBox.current.scrollTop

    }, 50)
  }
  componentDidMount() {
    // setTimeout(() => {
    //   if (this.props.scrollHight) {
    //     this.scrollBox.current.scrollTop = this.props.scrollHight
    //   }
    // }, 20);
    if (this.bs) {
      this.bs.refresh()
    }
    this.initBetterScroll()
  }
  refresh() {
    if (this.bs) {
      this.bs.refresh()
    }
  }
  initBetterScroll() {
    this.timmer = setTimeout(() => {
      this.bs = new BScroll(this.scrollBox.current, {
        pullDownRefresh: true,
        pullUpLoad: true,
        click: true
      })
      if (this.props.scrollHight) {
        this.bs.scrollTo(0, this.props.scrollHight, 0)
      }
      this.bs.on('pullingUp', () => {
        if (this.props.isCanLoading) {
          this.props.handleScroll(-400)
        }
        this.bs.finishPullUp()
      })
      // this.bs.on('pullingDown', () => {
      //   if (this.props.handleRefresh) {
      //     this.props.handleRefresh()
      //   }
      //   this.bs.finishPullDown()
      // })
      this.bs.on('scrollEnd', pos => {
        if (this.props.setScrollHight) {
          this.props.setScrollHight(pos.y)
        }
      })
      this.bs.on('scroll', (pos) => {
        
        if (pos.y > 20) {
          this.setState({
            totast: '继续下拉刷新',
            showToast: true
          })
        }
        if (pos.y > 40) {
          this.setState({
            totast: '松开刷新'
          })
        }
        if (pos.y >= 0 && pos.y < 10) {
          this.setState({
            showToast: false
          })
        }
        
      })
      this.bs.on('touchEnd', (pos) => {
        if (pos.y > 40) {
          this.setState({
            totast: '刷新中'
          })
          if (this.props.handleRefresh) {
            this.props.handleRefresh()
          }
        }
      })

    }, 90);
  }

  componentWillReceiveProps() {
    if (this.bs) {
      this.bs.refresh()
    }
  }

  componentWillUnmount() {
    if (this.timmer) {
      clearTimeout(this.props.timmer)
    }
  }





  render() {
    return (
      // <div className="scrollBox" ref={this.scrollBox} onScroll={this.handleScroll}>
      //   <div className="scrollBox-scroll" ref={this.scrollBoxScroll}>
      //     {this.props.children}
      //   </div>
      // </div>
      <div className="scrollBoxMain">
        {
          this.state.showToast ? (
            <div className="scrollBoxMainToast">
              <span>{this.state.totast}</span>
            </div>) : ''
        }
        <div className="scrollBox" ref={this.scrollBox}>
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default ScrollBox;